import {
  currentMeeting_map,
  currentHorse_map,
  currentRace_map,
  dbHorse_map,
  dbForm_map
} from "./dataset_maps";

// -- input types --
// string
// number (float)
// dropdown
// checkbox
// modular, based on another input

// all input fields are defined in param_inputs.
// the path of each field in the param is defined in paramPath
// {{comparex}} refers to the parent object, which
// is a compare1, compare2, compare3, compare4

// start with only 'type' input dropdown,
// when an item is selected in a dropdown,
// there may be a key in the dropdown object called 'inputs'
// which will be an array if it exists
// we want to then display every input field in that array,
// each defined by the inputType and dropdownOptions in param_inputs[key]

// the modular input is based on dataset_maps.js,
// when a field selection is made in compare1,
// it sets the staticValue/staticRange input in compare2 to
// the 'input' key of the selected item from dataset_maps
// compare3 works the same with compare4
const expandInputs = {
  compareInputs: [
    "compare1",
    "compare2",
    "compareFunc",
    "compare3",
    "compare4",
    "compareFunc3",
    "ifExists",
    "campaign",
  ],
  countInputs: [
    "compare1.count",
    "minvalue",
    "maxvalue",
    "campaign",
    "consecutive",
  ],
  percentInputs: [
    "compare1.percent",
    "compare2",
    "compareFunc",
    "compare3.percent",
    "compare4",
    "compareFunc3",
    "percentTarget",
    "percentCompare",
    "campaign",
  ],
  stdFieldInputs: ["modifyOperator", "modifyValue"],
  dbFormInputs: ["field.dbHorse", "number", "isJumps"],
  dbFormCountInputs: [
    "field.dbHorse",
    "number",
    "isJumps",
    "compare2",
    "compareFunc",
    "compare3",
    "compare4",
    "compareFunc3",
    "ifExists",
  ],
};
const dropdowns = {
  typeDropdown: [
    {
      label: "compare",
      value: "compare",
      inputs: expandInputs.compareInputs,
    },
    {
      label: "count",
      value: "count",
      inputs: expandInputs.countInputs,
    },
    {
      label: "percent",
      value: "percent",
      inputs: expandInputs.percentInputs,
    },
  ],
  phaseDropdown: [
    {
      label: "fixed value",
      value: "staticValue",
      inputs: ["value"],
    },
    {
      label: "fixed range",
      value: "staticRange",
      inputs: ["value1", "value2"],
    },
    {
      label: "upcoming horse",
      value: "currentHorse",
      inputs: expandInputs.stdFieldInputs.concat(["field.currentHorse"]),
    },
    {
      label: "upcoming race",
      value: "currentRace",
      inputs: expandInputs.stdFieldInputs.concat(["field.currentRace"]),
    },
    {
      label: "upcoming meeting",
      value: "currentMeeting",
      inputs: expandInputs.stdFieldInputs.concat(["field.currentMeeting"]),
    },
    {
      label: "horses db entry",
      value: "dbHorse",
      inputs: expandInputs.stdFieldInputs.concat(["field.dbHorse"]),
    },
    {
      label: "horses form",
      value: "dbForm",
      inputs: expandInputs.stdFieldInputs.concat(expandInputs.dbFormInputs),
    },
    {
      label: "horses form (with trials)",
      value: "dbTrialForm",
      inputs: expandInputs.stdFieldInputs.concat(expandInputs.dbFormInputs),
    },
  ],
  phasePercentDropdown: [
    {
      label: "horses form (with trials)",
      value: "dbTrialForm",
      inputs: expandInputs.stdFieldInputs.concat(expandInputs.dbFormInputs),
    },
    {
      label: "horses form",
      value: "dbForm",
      inputs: expandInputs.stdFieldInputs.concat(expandInputs.dbFormInputs),
    },
  ],
  phaseCountDropdown: [
    {
      label: "form count only",
      value: "dbForm",
    },
    {
      label: "form count only (with trials)",
      value: "dbTrialForm",
    },
    {
      label: "horses form",
      value: "dbForm",
      inputs: expandInputs.dbFormCountInputs.concat(
        expandInputs.stdFieldInputs
      ),
    },
    {
      label: "horses form (with trials)",
      value: "dbTrialForm",
      inputs: expandInputs.dbFormCountInputs.concat(
        expandInputs.stdFieldInputs
      ),
    },
  ],
  compareFuncDropdown: [
    {
      label: "is greater than",
      value: "greaterThan",
    },
    {
      label: "is less than",
      value: "lessThan",
    },
    {
      label: "is equal to",
      value: "equalTo",
    },
    {
      label: "is not equal to",
      value: "notEqualTo",
    },
    {
      label: "is inside range",
      value: "insideRange",
    },
    {
      label: "is outside range",
      value: "outsideRange",
    },
  ],
  campaignDropdown: [
    {
      label: "each",
      value: "each",
      inputs: ["campaignrun", "campaignrunexclude"],
    },
    {
      label: "current",
      value: "current",
      inputs: ["campaignrun", "campaignrunexclude"],
    },
    {
      label: "previous",
      value: "previous",
      inputs: ["campaignrun", "campaignrunexclude"],
    },
  ],
  modifyDropdown: [
    {
      label: "* (times)",
      value: "*",
    },
    {
      label: "/ (divided by)",
      value: "/",
    },
    {
      label: "- (less)",
      value: "-",
    },
    {
      label: "+ (plus)",
      value: "+",
    },
  ],
  jumpsDropdown : [
      {
          label: "",
          value: ""
      },
      {
          label: "yes",
          value: "true"
      },
      {
          label: "no",
          value: "false"
      }
  ],
};

const param_inputs = {
  type: {
    label: "type",
    paramPath: "type",
    inputType: "dropdown",
    dropdownOptions: dropdowns.typeDropdown,
  },
  "compare1.count": {
    label: "compare from",
    paramPath: "compare1.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phaseCountDropdown,
  },
  "compare3.count": {
    label: "compare from",
    paramPath: "compare1.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phaseCountDropdown,
  },
  "compare1.percent": {
    label: "compare from",
    paramPath: "compare1.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phasePercentDropdown,
  },
  "compare3.percent": {
    label: "compare from",
    paramPath: "compare1.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phasePercentDropdown,
  },
  compare1: {
    label: "compare from",
    paramPath: "compare1.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phaseDropdown,
  },
  compare2: {
    label: "compare from",
    paramPath: "compare2.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phaseDropdown,
  },
  compare3: {
    label: "compare from",
    paramPath: "compare1.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phaseDropdown,
  },
  compare4: {
    label: "compare from",
    paramPath: "compare4.phase",
    inputType: "dropdown",
    dropdownOptions: dropdowns.phaseDropdown,
  },
  compareFunc: {
    label: "",
    paramPath: "compareFunc",
    inputType: "dropdown",
    dropdownOptions: dropdowns.compareFuncDropdown,
  },
  compareFunc3: {
    label: "",
    paramPath: "compareFunc3",
    inputType: "dropdown",
    dropdownOptions: dropdowns.compareFuncDropdown,
  },
  ifExists: {
    label: "if form item exists",
    paramPath: "ifExists",
    inputType: "checkbox",
  },
  isJumps: {
      label: "is a jumps race",
      paramPath:"{{comparex}}.isJumps",
      inputType: dropdowns.jumpsDropdown,
  },
  minvalue: {
    label: "minimum matching count",
    paramPath: "minvalue",
    inputType: "number",
  },
  maxvalue: {
    label: "maximum matching count",
    paramPath: "maxvalue",
    inputType: "number",
  },
  campaign: {
    label: "In campaign",
    paramPath: "campaign",
    inputType: "dropdown",
    dropdownOptions: dropdowns.campaignDropdown,
  },
  campaignrun: {
    label: "campaign up",
    paramPath: "campaignrun",
    inputType: "number",
  },
  campaignrunexclude: {
    label: "exclude up",
    paramPath: "campaignrunexclude",
    inputType: "number",
  },
  number: {
    label: "number x recent run",
    paramPath: "{{comparex}}.number",
    inputType: "number",
  },
  modifyOperator: {
    label: "modify operator",
    paramPath: "{{comparex}}.modifyOperator",
    inputType: "dropdown",
    dropdownOptions: dropdowns.modifyDropdown,
  },
  modifyValue: {
    label: "modify value",
    paramPath: "{{comparex}}.modifyValue",
    inputType: "number",
  },
  percentTarget: {
    label: "target percentage",
    paramPath: "percentTarget",
    inputType: "number",
  },
  percentCompare: {
    label: "target percentage",
    paramPath: "percentTarget",
    inputType: "dropdown",
    dropdownOptions: dropdowns.campaignDropdown,
  },
  "field.dbForm": {
    label: "data point",
    paramPath: "{{comparex}}.field",
    inputType: "dropdown",
    dropdownOptions: dbForm_map,
  },
  "field.dbHorse": {
    label: "data point",
    paramPath: "{{comparex}}.field",
    inputType: "dropdown",
    dropdownOptions: dbHorse_map,
  },
  "field.currentHorse": {
    label: "data point",
    paramPath: "{{comparex}}.field",
    inputType: "dropdown",
    dropdownOptions: currentHorse_map,
  },
  "field.currentRace": {
    label: "data point",
    paramPath: "{{comparex}}.field",
    inputType: "dropdown",
    dropdownOptions: currentRace_map,
  },
  "field.currentMeeting": {
    label: "data point",
    paramPath: "{{comparex}}.field",
    inputType: "dropdown",
    dropdownOptions: currentMeeting_map,
  },
  value: {
    label: "static value",
    paramPath: "{{comparex}}.value",
    inputType: "modular",
    dropdownOptions: [], // based on input to corresponding field selection. discuss.
  },
  value1: {
    label: "static value",
    paramPath: "{{comparex}}.value1",
    inputType: "modular",
    dropdownOptions: [], // based on input to corresponding field selection. discuss.
  },
  value2: {
    label: "static value",
    paramPath: "{{comparex}}.value2",
    inputType: "modular",
    dropdownOptions: [], // based on input to corresponding field selection. discuss.
  },
};

export { param_inputs, dropdowns, expandInputs };
