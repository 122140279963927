const datapoint_dropdowns = {
  "boolOptions": [
      {value:"Y",label:"true"},
      {value:"N",label:"false"}
  ],
  "tabOptions" : [
      {value:"T",label:"Tab"},
      {value:"N",label:"Non-tab"},
      {value:"Trial",label:"Trial"}
  ],
  "conditionOptions": [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 }
  ],
  "locationOptions" : [
      {value:"M",label:"Metro"},
      {value:"C",label:"Country"},
      {value:"P",label:"Provincial"}
  ],
  "surfaceOptions" : [
      { value: "G", label: "Grass" },
      { value: "Y", label: "Synthetic" },
      { value: "D", label: "Dirt" },
      { value: "S", label: "Sand" }
  ],
  "stateOptions":[
      { label: "ACT", value: 1 },
      { label: "NSW", value: 2 },
      { label: "VIC", value: 3 },
      { label: "QLD", value: 4 },
      { label: "SA", value: 5 },
      { label: "WA", value: 6 },
      { label: "TAS", value: 7 },
      { label: "NT", value: 8 },
      { label: "NZ", value: 9 },
      { label: "HK", value: 10 },
      { label: "SGP", value: 11 },
      { label: "AUS", value: 99 }
  ],
  "directionOptions":[
      { value: "A", label: "Anti-clockwise" },
      { value: "C", label: "Clockwise" }
  ],
  "ageOptions":[
      { value: 1, label: "2yo" },
      { value: 2, label: "2yo+" },
      { value: 3, label: "2,3,4yo" },
      { value: 4, label: "3yo" },
      { value: 5, label: "3yo+" },
      { value: 6, label: "4yo" },
      { value: 7, label: "4yo+" },
      { value: 8, label: "5yo" },
      { value: 9, label: "5yo+" },
      { value: 10, label: "6yo" },
      { value: 11, label: "Aged" },
      { value: 12, label: "2,3yo" },
      { value: 13, label: "3,4yo" },
      { value: 14, label: "3,4,5yo" },
      { value: 15, label: "4,5yo" },
      { value: 16, label: "4,5,6yo" },
      { value: 17, label: "3,4,5,6" }
  ],
  "sexOptions":[
      { value: 1, label: "Fillies" },
      { value: 2, label: "Mares" },
      { value: 3, label: "Colts" },
      { value: 4, label: "Geldings" },
      { value: 5, label: "Horses" },
      { value: 6, label: "Fillies & Mares" },
      { value: 7, label: "Colts & Horses" },
      { value: 8, label: "Colts & Geldings" },
      { value: 9, label: "Horses & Geldings" },
      { value: 10, label: "Horses & Mares" },
      { value: 11, label: "Colts & Fillies" },
      { value: 12, label: "Colts, Horses & Geldings" },
      { value: 13, label: "Fillies, Colts & Geldings" },
      { value: 14, label: "Mares, Horses & Geldings" },
      { value: 15, label: "Fillies, Mares, Colts & Horses" },
      { value: 16, label: "Fillies, Mares, Colts & Geldings" }
  ],
  "jockeyOptions":[
      { value: "C", label: "Apprentices Can Claim" },
      { value: "A", label: "Apprentice Riders Only" },
      { value: "E", label: "Eligible Riders Can Claim" },
      { value: "G", label: "Gentlemen Riders Only" },
      { value: "I", label: "Invited Riders" },
      { value: "L", label: "Lady Riders Only" },
      { value: "N", label: "Apprentices Cannot Claim" },
      { value: "X", label: "Eligible Riders Cannot Claim" },
      { value: "M", label: "Amateur Riders" },
      { value: "H", label: "Hurdle Jockeys Only" }
  ],
  "weightclassOptions" : [
      { value: "H", label: "Handicap" },
      { value: "W", label: "Weight For Age" },
      { value: "X", label: "Weight For Age With Penalties" },
      { value: "S", label: "Set Weight" },
      { value: "T", label: "Set Weight With Penalties" },
      { value: "U", label: "Set Weight With Penalties and Allowances" },
      { value: "P", label: "Special Weight" },
      { value: "C", label: "Catch Weight" },
      { value: "F", label: "Fly Weight" },
      { value: "A", label: "Set Weight With Allowances" },
      { value: "Q", label: "Quality" }
  ],
  "groupOptions":[
      { value: 1, label: "Group 1" },
      { value: 2, label: "Group 2" },
      { value: 3, label: "Group 3" },
      { value: "LR", label: "Listed Race" }
  ],
  "racetypeOptions": [
      { value: "Flat", label: "Flat" },
      { value: "Jumps", label: "Jumps" }
  ],
  "colourOptions": [
      { value: 1, label: "CHESTNUT" },
      { value: 2, label: "BAY" },
      { value: 3, label: "BROWN" },
      { value: 4, label: "BLACK" },
      { value: 5, label: "GREY" },
      { value: 9, label: "WHITE" },
      { value: 14, label: "BAY OR BROWN" },
      { value: 15, label: "BROWN OR BLACK" },
      { value: 16, label: "GREY-CHESTNUT" },
      { value: 17, label: "GREY-BAY" },
      { value: 18, label: "GREY-BROWN" },
      { value: 19, label: "GREY-BLACK" },
      { value: 20, label: "GREY-ROAN" },
      { value: 21, label: "ROAN"}
    ],
    "horsesexOptions": [
      { value: "F", label: "Filly" },
      { value: "C", label: "Colt" },
      { value: "G", label: "Gelding" },
      { value: "M", label: "Mare" },
      { value: "R", label: "Rig" },
      { value: "H", label: "Horse" },
    ],
    "apprenticeindOptions":[
      { value: "Y", label: "true" }
    ]
}
const currentMeeting_map = [
  {value:"meetingDate",label:"meeting date", input: Date},
  {value:"processedMeetingData.meeting.rail_position",label:"rail position", input: String},
  {value:"processedMeetingData.meeting.tab_indicator",label:"tab indicator", input:datapoint_dropdowns.tabOptions},
  {value:"processedMeetingData.meeting.dual_track",label:"dual track status", input:datapoint_dropdowns.boolOptions},
  {value:"processedMeetingData.meeting.track.@_expected_condition",label:"expected condition", input:datapoint_dropdowns.conditionOptions, condition:true},
  {value:"processedMeetingData.meeting.track.@_location",label:"meeting classification", input:datapoint_dropdowns.locationOptions},
  {value:"processedMeetingData.meeting.track.@_night_meeting",label:"night meeting indicator", input:datapoint_dropdowns.boolOptions},
  {value:"processedMeetingData.meeting.track.@_name",label:"track name", input: String},
  {value:"processedMeetingData.meeting.track.@_id",label:"track ID", input: Number},
  {value:"processedMeetingData.meeting.track.@_track_surface",label:"track surface", input:datapoint_dropdowns.surfaceOptions},
  {value:"processedMeetingData.meeting.track.@_country",label:"track country", input: String},
  {value:"processedMeetingData.meeting.track.@_state",label:"track state", input:datapoint_dropdowns.stateOptions},
  {value:"processedMeetingData.meeting.track.racing_direction",label:"track racing direction", input:datapoint_dropdowns.directionOptions},
  {value:"processedMeetingData.meeting.track.@_club",label:"racing club", input: String}
]

const dbForm_map = [
  {value:"meeting_date",label:"meeting date", input: Date},
  {value:"night_meeting",label:"night meeting indicator", input:datapoint_dropdowns.apprenticeindOptions},
  {value:"rail_position",label:"rail position", input: String},
  {value:"track.@_name",label:"track name", input: String},
  {value:"track.@_id",label:"track ID", input: Number},
  {value:"track.@_location",label:"meeting classification", input:datapoint_dropdowns.locationOptions},
  {value:"track.@_grading",label:"track condition (numerical)", input:datapoint_dropdowns.conditionOptions, condition:true},
  {value:"track.@_track_surface",label:"track surface", input:datapoint_dropdowns.surfaceOptions},
  {value:"track.@_country",label:"track country", input: String},
  {value:"track.@_state",label:"track state", input:datapoint_dropdowns.stateOptions},
  {value:"weight_type",label:"weight type", input:datapoint_dropdowns.weightclassOptions},
  {value:"limit_weight",label:"limit weight", input: Number},
  {value:"race.@_number",label:"race number", input: Number},
  {value:"race.@_name",label:"race name", input: String},
  {value:"distance.@_metres",label:"distance", input: Number, distance:true},
  {value:"event_duration",label:"duration", input: Number},
  {value:"restrictions.@_age",label:"age restriction", input:datapoint_dropdowns.ageOptions},
  {value:"restrictions.@_sex",label:"sex restriction", input:datapoint_dropdowns.sexOptions},
  {value:"restrictions.@_jockey",label:"jockey restriction", input:datapoint_dropdowns.jockeyOptions},
  {value:"group",label:"group", input:datapoint_dropdowns.groupOptions},
  {value:"official_margin_1",label:"official margin 1", input: String},
  {value:"official_margin_2",label:"official margin 2", input: String},
  {value:"beaten_margin",label:"beaten margin", input: Number},
  {value:"margin",label:"margin", input: Number},
  {value:"starters",label:"starters", input: Number},
  {value:"classes.class",label:"class name", input: String},
  {value:"classes.class_id",label:"class ID", input: Number},
  {value:"classes.bracket",label:"class bracket", input: Number},
  {value:"event_prizemoney",label:"race prizemoney", input: Number},
  {value:"sectional.@_distance",label:"sectional distance", input: Number},
  {value:"sectional.@_time",label:"sectional time", input: Number},
  {value:"sectional.@_location",label:"sectional location", input: Number},
  {value:"trainer_id",label:"trainer ID", input: Number},
  {value:"jockey.@_id",label:"jockey ID", input: Number},
  {value:"jockey.@_apprentice_indicator",label:"jockey apprentice indicator", input:datapoint_dropdowns.apprenticeindOptions},
  {value:"weight_carried",label:"weight carried", input: Number},
  {value:"weight_allocated",label:"weight allocated", input: Number},
  {value:"barrier",label:"barrier", input: Number},
  {value:"decimalprices.@_opening",label:"opening price", input: Number},
  {value:"decimalprices.@_mid",label:"mid price", input: Number},
  {value:"decimalprices.@_starting",label:"starting price", input: Number},
  {value:"finish_position",label:"finish position", input: String},
  {value:"positions.@_settling_down",label:"settling position", input: Number},
  {value:"positions.@_m400",label:"400 position", input: Number},
  {value:"positions.@_m800",label:"800 position", input: Number},
  {value:"positions.@_m1200",label:"1200 position", input: Number},
  {value:"horse_prizemoney",label:"horse prizemoney", input: Number},
  {value:"horse_prizemoney_bonus",label:"horse bonus prizemoney", input: Number},
  {value:"stewards_report",label:"stewards report", input: String},
  {value:"rating.@_adjusted",label:"rating", input: Number},
  {value:"rating.@_handicap",label:"handicap rating", input: Number},
  {value:"rating.@_post_handicap",label:"post handicap rating", input: Number},
  {value:"days_since_last_run",label:"days since prior form run", input: Number}
]
const currentRace_map = [
  {value:"@_number",label:"race number", input: Number},
  {value:"@_name",label:"race name", input: String},
  {value:"@_nominations_number",label:"nominations number", input: Number},
  {value:"track.@_name",label:"track name", input: String},
  {value:"track.@_id",label:"track ID", input: Number},
  {value:"track.@_track_surface",label:"track surface", input:datapoint_dropdowns.surfaceOptions},
  {value:"track.@_state",label:"track state", input:datapoint_dropdowns.stateOptions},
  {value:"distance.@_metres",label:"distance", input: Number, distance:true},
  {value:"restrictions.@_age",label:"age restrictions", input:datapoint_dropdowns.ageOptions},
  {value:"restrictions.@_sex",label:"sex restrictions", input:datapoint_dropdowns.sexOptions},
  {value:"restrictions.@_jockey",label:"jockey restrictions", input:datapoint_dropdowns.jockeyOptions},
  {value:"starters",label:"starters", input: Number},
  {value:"weight_type",label:"weight type", input:datapoint_dropdowns.weightclassOptions},
  {value:"min_hcp_weight",label:"minimum handicap weight", input: Number},
  {value:"group",label:"group", input:datapoint_dropdowns.groupOptions},
  {value:"classes.class",label:"class name", input: String},
  {value:"classes.class_id",label:"class ID", input: Number},
  {value:"classes.bracket",label:"class bracket", input: Number},
  {value:"race_type",label:"race type", input:datapoint_dropdowns.racetypeOptions},
  {value:"prize.total",label:"prizemoney total", input: Number},
  {value:"start_time",label:"start time", input: Number},
  {value:"records.track_record.meeting_date",label:"record meeting date", input: Date},
  {value:"records.track_record.distance.@_metres",label:"record distance", input: Number},
  {value:"records.track_record.duration",label:"record duration", input: Number},
  {value:"records.track_record.horse.jockey.@_id",label:"record jockey ID", input: Number},
  {value:"records.track_record.horse.weight_carried",label:"record weight carried", input: Number},
  {value:"records.track_record.horse.@_name",label:"record horse name", input: String},
  {value:"records.track_record.horse.@_country",label:"record horse country", input: String},
  {value:"records.track_record.horse.@_id",label:"record horse ID", input: Number},
  {value:"temporary_weight_change",label:"temporary weight change", input: Number}
]

const currentHorse_map = [
  {value:"@_country",label:"country", input: String},
  {value:"@_age",label:"age", input: Number},
  {value:"@_colour",label:"colour", input:datapoint_dropdowns.colourOptions},
  {value:"@_sex",label:"sex", input:datapoint_dropdowns.horsesexOptions},
  {value:"@_foalingdate",label:"foaling date", input: Date},
  {value:"betting",label:"betting", input: Number},
  {value:"barrier",label:"barrier", input: Number},
  {value:"trainer.@_id",label:"trainer ID", input: Number},
  {value:"training_location",label:"training location", input: String},
  {value:"prizemoney_won",label:"prizemoney won", input: Number},
  {value:"win_percentage",label:"win percentage", input: Number},
  {value:"place_percentage",label:"place percentage", input: Number},
  {value:"pace_value",label:"pace value", input: Number},
  {value:"ballot_sequence",label:"ballot sequence", input: Number},
  {value:"handicap_rating",label:"handicap rating", input: Number},
  {value:"tip",label:"tip", input: Number},
  {value:"weight.@_allocated",label:"allocated weight", input: Number},
  {value:"weight.@_total",label:"total weight", input: Number},
  {value:"tab_number",label:"TAB number", input: Number},
  {value:"jockey.@_apprentice_indicator",label:"jockey apprentice indicator", input:datapoint_dropdowns.apprenticeindOptions},
  {value:"jockey.@_allowance_weight",label:"jockey allowance weight", input: Number},
  {value:"jockey.@_id",label:"jockey ID", input: Number},
  {value:"sire.@_name",label:"sire name", input: String},
  {value:"sire.@_country",label:"sire country", input: String},
  {value:"sire.@_id",label:"sire ID", input: Number},
  {value:"dam.@_name",label:"dam name", input: String},
  {value:"dam.@_country",label:"dam country", input: String},
  {value:"dam.@_id",label:"dam ID", input: Number},
  {value:"sire_of_dam.@_name",label:"sire of dam name", input: String},
  {value:"sire_of_dam.@_country",label:"sire of dam country", input: String},
  {value:"sire_of_dam.@_id",label:"sire of dam ID", input: Number},
  {value:"rating",label:"predictive rating", input: Number},
  {value:"rating_wfa",label:"predictive wfa rating", input: Number},
  {value:"owners",label:"owners", input: String},
  {value:"current_blinker_ind",label:"current blinker indicator", input:datapoint_dropdowns.apprenticeindOptions}
]

const dbHorse_map = [
  {value:"HOR_RACING_COLOURS", label:"racing colours", input:Number},
  {value:"HOR_START_TOTAL",label:"total starts", input:Number},
  {value:"HOR_H_1STS_TOTAL",label:"total wins", input:Number},
  {value:"HOR_H_2NDS_TOTAL",label:"total 2nds", input:Number},
  {value:"HOR_H_3RDS_TOTAL",label:"total 3rds", input:Number},
  {value:"HOR_H_4THS_TOTAL",label:"total 4ths", input:Number},
  {value:"HOR_H_5THS_TOTAL",label:"total 5ths", input:Number},
  {value:"HOR_TOTAL_PRIZEMONEY",label:"total prizemoney", input:Number},
  {value:"HOR_CURRENT_BLINKER_IND",label:"", input:datapoint_dropdowns.apprenticeindOptions},
  {value:"HOR_WET_START_TOTAL",label:"wet starts", input:Number},
  {value:"HOR_WET_1ST_TOTAL",label:"Wet wins", input:Number},
  {value:"HOR_WET_2ND_TOTAL",label:"wet 2nds", input:Number},
  {value:"HOR_WET_3RD_TOTAL",label:"wet 3rds", input:Number},
  {value:"HOR_DRY_START_TOTAL",label:"Dry starts", input:Number},
  {value:"HOR_DRY_1ST_TOTAL",label:"Dry wins", input:Number},
  {value:"HOR_DRY_2ND_TOTAL",label:"Dry 2nds", input:Number},
  {value:"HOR_DRY_3RD_TOTAL",label:"Dry 3rds", input:Number},
  {value:"HOR_OWNER_NAMES",label:"Owner names", input:String}

]


export {
  dbForm_map,
  datapoint_dropdowns,
  currentMeeting_map,
  currentRace_map,
  currentHorse_map,
  dbHorse_map
}