<template>
  <div class="rules-manager-page">
    <div v-if="!isEditing" class="rules-manager">
      <!-- Active Rules Section -->
      <Card class="mb-4">
        <template #title>
          <div class="flex justify-between items-center">
            <span>Active Rules </span>
            <div class="search-container w-64">
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText
                  v-model="searchQuery"
                  placeholder="Search rules..."
                  class="w-full"
                  @input="handleSearch"
                />
              </span>
            </div>
          </div>
        </template>
        <template #content>
          <div
            v-if="filteredGroups && filteredGroups.length"
            class="accordion-container"
          >
            <Accordion
              v-model:activeIndex="openTabs"
              :multiple="true"
              @tab-open="
                (e) => updateOpenTabs([...openTabs, e.index.toString()])
              "
              @tab-close="
                (e) =>
                  updateOpenTabs(
                    openTabs.filter((tab) => tab !== e.index.toString())
                  )
              "
            >
              <AccordionTab
                v-for="(group, index) in filteredGroups"
                :key="index"
              >
                <template #header>
                  {{ group.name }} ({{ group.rules ? group.rules.length : 0 }}
                  rules)
                </template>

                <div
                  v-if="group.rules && group.rules.length > 0"
                  class="rules-container"
                >
                  <div
                    v-for="rule in group.rules"
                    :key="rule.name"
                    class="rule-card"
                    :class="{ 'highlight-rule': isRuleMatched(rule) }"
                  >
                    <!-- Rule Header -->
                    <div class="rule-header">
                      <div class="rule-title">
                        <h3>{{ rule.name }}</h3>
                        <Badge
                          :value="rule.active ? 'Active' : 'Inactive'"
                          :severity="rule.active ? 'success' : 'danger'"
                        />
                      </div>
                      <div class="rule-actions">
                        <Button
                          icon="pi pi-pencil"
                          class="p-button-text p-button-sm"
                          @click="onUpdateRule(rule)"
                        />
                      </div>
                    </div>

                    <!-- Rule Content -->
                    <div class="rule-content">
                      <div class="rule-info">
                        <span class="rule-order">Order: {{ rule.order }}</span>
                        <p class="rule-description">{{ rule.description }}</p>
                      </div>

                      <!-- Sentences Section -->
                      <div
                        v-if="rule.sentences && rule.sentences.length"
                        class="rule-sentences"
                      >
                        <h4>Sentences</h4>
                        <ul>
                          <li
                            v-for="sentence in rule.sentences"
                            :key="sentence.order"
                          >
                            {{ sentence.sentence }}
                          </li>
                        </ul>
                      </div>

                      <!-- Parameters Section -->
                      <div
                        v-if="rule.ruleParameters && rule.ruleParameters.length"
                        class="rule-parameters"
                      >
                        <h4>Parameters</h4>
                        <ul>
                          <li
                            v-for="(param, index) in rule.ruleParameters"
                            :key="index"
                          >
                            {{ translateParam(param) }}
                          </li>
                        </ul>
                      </div>

                      <!-- Direction Indicator -->
                      <div
                        class="rule-direction"
                        :class="getDirectionClass(rule.direction)"
                      >
                        {{ getDirectionText(rule.direction) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="no-rules">No rules in this group</div>
              </AccordionTab>
            </Accordion>
          </div>
          <div v-else class="no-groups">No rule groups available</div>
        </template>
      </Card>
    </div>
    <div class="rule-wizard" :class="{ 'editing-mode': isEditing }">
      <Card>
        <template #title>
          <div class="flex justify-between items-center">
            <span>
              {{ isEditing ? "Edit Rule" : "Create New Rule" }} - Step
              {{ currentStep + 1 }} of 4
            </span>
            <div class="card">
              <Steps
                :model="steps"
                v-model:activeStep="currentStep"
                :readonly="false"
              >
                <template #item="{ item }">
                  <li
                    class="custom-step-item"
                    :class="{
                      'custom-step-active': currentStep === getStepIndex(item),
                      'custom-step-valid':
                        steps[getStepIndex(item)].validationStatus === 'valid',
                      'custom-step-invalid':
                        steps[getStepIndex(item)].validationStatus ===
                        'invalid',
                    }"
                    @click="handleStepClick(item)"
                  >
                    <a class="custom-step-link">
                      <span class="custom-step-number">{{
                        getStepIndex(item) + 1
                      }}</span>
                      <span class="custom-step-title">{{ item.label }}</span>
                    </a>
                  </li>
                </template>
              </Steps>
            </div>
          </div>
        </template>
        <template #content>
          <!-- Step 1: Basic Information -->
          <div v-if="currentStep === 0" class="step-content">
            <div class="form-grid">
              <div class="form-field">
                <label for="groupName">Group Name*</label>
                <Dropdown
                  id="groupName"
                  v-model="formData.basic.groupName"
                  :options="filteredGroups"
                  optionLabel="name"
                  optionValue="name"
                  :editable="true"
                  class="w-full"
                  :class="{ 'p-invalid': stepErrors.basic.groupName }"
                />
                <small v-if="isNewGroup" class="text-blue-500">
                  This group will be created as new.
                </small>
                <small class="text-red-500">{{
                  stepErrors.basic.groupName
                }}</small>
              </div>

              <div class="form-field">
                <label for="ruleName">Rule Name*</label>
                <InputText
                  id="ruleName"
                  variant
                  v-model="formData.basic.name"
                  class="w-full"
                  :class="{ 'p-invalid': stepErrors.basic.name }"
                />
                <small class="text-red-500">{{ stepErrors.basic.name }}</small>
              </div>

              <div class="form-field">
                <label for="description">Description*</label>
                <Textarea
                  id="description"
                  v-model="formData.basic.description"
                  rows="3"
                  class="w-full"
                  :class="{ 'p-invalid': stepErrors.basic.description }"
                />
                <small class="text-red-500">{{
                  stepErrors.basic.description
                }}</small>
              </div>

              <div class="flex gap-4">
                <div class="form-field mr-4">
                  <label for="order">Order*</label>
                  <InputNumber
                    id="order"
                    v-model="formData.basic.order"
                    class="w-full"
                  />
                </div>

                <div class="form-field mr-4">
                  <label>Direction*</label>
                  <div class="flex gap-2">
                    <RadioButton
                      v-model="formData.basic.direction"
                      :value="1"
                      inputId="direction1"
                    />
                    <label for="direction1">Positive</label>

                    <RadioButton
                      v-model="formData.basic.direction"
                      :value="-1"
                      inputId="direction-1"
                    />
                    <label for="direction-1">Negative</label>
                    <RadioButton
                      v-model="formData.basic.direction"
                      :value="0"
                      inputId="direction1"
                    />
                    <label for="direction1">Neutral</label>
                  </div>
                </div>

                <div class="form-field mr-4">
                  <label>Status</label>
                  <div class="flex align-items-center gap-2">
                    <Checkbox
                      v-model="formData.basic.active"
                      :binary="true"
                      inputId="active"
                    />
                    <label for="active">Active</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Step 2: Parameters -->
          <div v-if="currentStep === 1" class="step-content">
            <div class="parameters-container">
              <div
                v-for="(param, index) in formData.parameters"
                :key="index"
                class="parameter-item mb-4 p-4 border rounded"
                :class="{
                  'border-red-500':
                    Object.keys(stepErrors.parameters[index] || {}).length > 0,
                }"
              >
                <!-- Summary view: clicking this toggles the edit view -->
                <div class="d-flex justify-content-between align-items-center">
                  <!-- Clickable summary -->
                  <div @click="toggleParameter(index)" style="cursor: pointer">
                    <strong>Parameter {{ index + 1 }}: {{ param.type }}</strong>
                    <span v-if="!expandedParameters.includes(index)">
                      {{ translateParam(param) }}
                    </span>
                  </div>
                  <!-- Edit and Delete buttons -->
                  <div class="d-flex gap-2">
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-text"
                      @click.stop="toggleParameter(index)"
                    />
                    <Button
                      icon="pi pi-trash"
                      class="p-button-danger p-button-text"
                      @click.stop="removeParameter(index)"
                      :disabled="formData.parameters.length === 1"
                    />
                  </div>
                </div>
                <!-- Editable view: only shown if this parameter is expanded -->
                <!-- Parameter Edit Form -->
                <div
                  v-if="expandedParameters.includes(index)"
                  class="mt-4 bg-gray-50"
                >
                  <!-- Parameter Type Selection -->
                  <div class="form-field mb-4 bg-gradient-dark">
                    <label>Parameter Type</label>
                    <Dropdown
                      v-model="param.type"
                      :options="dropdowns.typeDropdown"
                      optionLabel="label"
                      optionValue="value"
                      class="w-full"
                      :class="{ 'p-invalid': getParameterError(index, 'type') }"
                      @change="handleParameterTypeChange(param, $event.value)"
                    />
                    <small class="text-red-500">{{
                      getParameterError(index, "type")
                    }}</small>
                    <!-- Campaign Section with dropdown options -->
                    <div v-if="param.type" class="flex gap-4 mb-4">
                      <div class="flex-1">
                        <label>Campaign</label>
                        <Dropdown
                          v-model="param.campaign"
                          :options="dropdowns.campaignDropdown"
                          optionLabel="label"
                          optionValue="value"
                          class="w-full"
                        />
                      </div>
                      <template
                        v-if="
                          ['each', 'current', 'previous'].includes(
                            param.campaign
                          )
                        "
                      >
                        <div class="flex-1">
                          <label>Campaign Run</label>
                          <InputNumber
                            v-model="param.campaignrun"
                            class="w-full"
                          />
                        </div>
                        <div class="flex-1">
                          <label>Campaign Run Exclude</label>
                          <InputNumber
                            v-model="param.campaignrunexclude"
                            class="w-full"
                          />
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- Compare Type Fields Section -->
                  <template v-if="param.type === 'compare'">
                    <!-- Compare 1 Section -->
                    <div class="grid gap-4">
                      <div class="col-12 md:col-6">
                        <h4 class="mt-0">Compare 1</h4>
                        <div class="flex gap-3">
                          <div class="flex-1">
                            <label>Phase</label>
                            <Dropdown
                              v-model="param.compare1.phase"
                              :options="dropdowns.phaseDropdown"
                              optionLabel="label"
                              optionValue="value"
                              placeholder="Select phase"
                              class="w-full"
                              :class="{
                                'p-invalid': getParameterError(index, 'phase1'),
                              }"
                              @change="
                                handlePhaseChange(
                                  param,
                                  'compare1',
                                  $event.value
                                )
                              "
                            />
                            <small class="text-red-500">{{
                              getParameterError(index, "phase1")
                            }}</small>
                          </div>
                          <!-- Field Dropdown and Dynamic Input for Compare 1 (if phase is not static) -->
                          <template
                            v-if="
                              param.compare1.phase !== 'staticValue' &&
                              param.compare1.phase !== 'staticRange'
                            "
                          >
                            <div class="form-field mb-2">
                              <label>Field</label>
                              <Dropdown
                                v-model="param.compare1.field"
                                :options="getFieldOptions(param.compare1.phase)"
                                optionLabel="label"
                                optionValue="value"
                                class="w-full"
                                @change="
                                  handleFieldChange(
                                    param,
                                    'compare1',
                                    $event.value
                                  )
                                "
                              />
                            </div>
                          </template>
                          <template
                            v-else-if="param.compare1.phase === 'staticValue'"
                          >
                            <div class="form-field mb-2">
                              <label>Value</label>
                              <InputText
                                v-model="param.compare2.value1"
                                class="w-full"
                              />
                            </div>
                          </template>
                          <template
                            v-else-if="param.compare1.phase === 'staticRange'"
                          >
                            <div class="form-field mb-2">
                              <label>Range Start</label>
                              <InputText
                                v-model="param.compare2.value1"
                                class="w-full"
                              />
                            </div>
                            <div class="form-field mb-2">
                              <label>Range End</label>
                              <InputText
                                v-model="param.compare2.value2"
                                class="w-full"
                              />
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <!-- Compare Function Dropdown -->
                    <div class="col-12 md:col-12">
                      <div class="form-field">
                        <label>Compare Function</label>
                        <Dropdown
                          v-model="param.compareFunc"
                          :options="dropdowns.compareFuncDropdown"
                          optionLabel="label"
                          optionValue="value"
                          class="w-full"
                        />
                      </div>
                    </div>
                    <div class="flex items-center mt-6">
                      <Checkbox
                        v-model="param.compare1.isJumps"
                        :binary="true"
                      />
                      <label class="ml-2">Is Jumps</label>
                    </div>
                    <!-- Compare 2 Section -->
                    <div>
                      <h4>Compare 2</h4>
                      <div class="flex gap-3">
                        <!-- Phase Dropdown for Compare 2 -->
                        <div class="flex-1">
                          <label>Phase</label>
                          <Dropdown
                            v-model="param.compare2.phase"
                            :options="dropdowns.phaseDropdown"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select phase"
                            class="w-full"
                            @change="
                              handlePhaseChange(param, 'compare2', $event.value)
                            "
                          />
                        </div>
                        <div class="flex-1">
                          <div
                            class="form-field"
                            v-if="
                              param.compare1.field &&
                              param.compare1.field !== 'NA'
                            "
                          >
                            <!-- Dynamic Input based on field type -->
                            <template
                              v-if="
                                getFieldConfig(
                                  param.compare1.phase,
                                  param.compare1.field
                                ) && param.compare1.phase !== 'staticValue'
                              "
                            >
                              <label>
                                {{
                                  capitalizeFirstLetters(
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    )?.label || "Select Field"
                                  )
                                }}
                              </label>
                              <!-- Render InputText if field is a String -->
                              <InputText
                                v-if="
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  ).input === String
                                "
                                v-model="param.compare1.fieldValue"
                                class="w-full"
                              />
                              <!-- Render InputNumber if field is a Number -->
                              <InputNumber
                                v-else-if="
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  ).input === Number
                                "
                                v-model="param.compare1.fieldValue"
                                class="w-full"
                              />
                              <!-- Render Calendar if field is a Date -->
                              <Calendar
                                v-else-if="
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  ).input === Date
                                "
                                v-model="param.compare1.fieldValue"
                                dateFormat="yy-mm-dd"
                                class="w-full"
                              />
                              <!-- Render Dropdown if field is defined as an array -->
                              <Dropdown
                                v-else-if="
                                  Array.isArray(
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    ).input
                                  )
                                "
                                v-model="param.compare1.fieldValue"
                                :options="
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  ).input
                                "
                                optionLabel="label"
                                optionValue="value"
                                class="w-full"
                              />
                            </template>
                            <template v-if="param.compare1.phase === 'dbForm'">
                              <div class="flex-1">
                                <label>Number</label>
                                <InputNumber
                                  v-model="param.compare1.number"
                                  class="w-full"
                                />
                              </div>
                            </template>
                          </div>
                        </div>
                        <div class="flex-1">
                          <template
                            v-if="
                              param.compare2.phase !== 'staticValue' &&
                              param.compare2.phase !== 'staticRange'
                            "
                          >
                            <div class="form-field mb-2">
                              <label>Field</label>
                              <Dropdown
                                v-model="param.compare2.field"
                                :options="getFieldOptions(param.compare2.phase)"
                                optionLabel="label"
                                optionValue="value"
                                class="w-full"
                                @change="
                                  handleFieldChange(
                                    param,
                                    'compare1',
                                    $event.value
                                  )
                                "
                              />
                            </div>
                            <div
                              class="form-field"
                              v-if="
                                param.compare2.field &&
                                param.compare2.field !== 'NA'
                              "
                            >
                              <template
                                v-if="
                                  getFieldConfig(
                                    param.compare2.phase,
                                    param.compare2.field
                                  ) &&
                                  Array.isArray(
                                    getFieldConfig(
                                      param.compare2.phase,
                                      param.compare2.field
                                    ).input
                                  )
                                "
                              >
                                <label>
                                  {{
                                    capitalizeFirstLetters(
                                      getFieldConfig(
                                        param.compare2.phase,
                                        param.compare2.field
                                      )?.label || "Select Field"
                                    )
                                  }}
                                </label>
                                <Dropdown
                                  v-model="param.compare2.value"
                                  :options="
                                    getFieldConfig(
                                      param.compare2.phase,
                                      param.compare2.field
                                    ).input
                                  "
                                  optionLabel="label"
                                  optionValue="value"
                                  class="w-full"
                                />
                              </template>
                            </div>
                          </template>
                          <template
                            v-if="
                              getFieldConfig(
                                param.compare2.phase,
                                param.compare2.field
                              ) &&
                              getFieldConfig(
                                param.compare2.phase,
                                param.compare2.field
                              ).input === Number
                            "
                          >
                            <label>
                              {{
                                capitalizeFirstLetters(
                                  getFieldConfig(
                                    param.compare2.phase,
                                    param.compare2.field
                                  )?.label || "Select Field"
                                )
                              }}
                            </label>
                            <InputNumber
                              v-model="param.compare2.value"
                              class="w-full"
                            />
                          </template>
                          <template
                            v-if="
                              getFieldConfig(
                                param.compare2.phase,
                                param.compare2.field
                              ) &&
                              getFieldConfig(
                                param.compare2.phase,
                                param.compare2.field
                              ).input === Date
                            "
                          >
                            <label>
                              {{
                                capitalizeFirstLetters(
                                  getFieldConfig(
                                    param.compare2.phase,
                                    param.compare2.field
                                  )?.label || "Select Field"
                                )
                              }}
                            </label>
                            <Calendar
                              v-model="param.compare2.fieldValue"
                              dateFormat="yy-mm-dd"
                              class="w-full"
                            />
                          </template>
                          <template
                            v-if="
                              getFieldConfig(
                                param.compare2.phase,
                                param.compare2.field
                              ) &&
                              getFieldConfig(
                                param.compare2.phase,
                                param.compare2.field
                              ).input === String
                            "
                          >
                            <label>
                              {{
                                capitalizeFirstLetters(
                                  getFieldConfig(
                                    param.compare2.phase,
                                    param.compare2.field
                                  )?.label || "Select Field"
                                )
                              }}
                            </label>
                            <InputText
                              v-model="param.compare2.fieldValue"
                              class="w-full"
                            />
                          </template>
                        </div>
                        <div
                          class="flex-1"
                          v-if="param.compare2.number !== undefined"
                        >
                          <label>Number of Recent Runs</label>
                          <InputNumber
                            v-model="param.compare2.number"
                            class="w-full"
                          />
                        </div>
                        <div
                          class="form-field mb-2"
                          v-if="param.compare2.modifyOperator"
                        >
                          <label>Modify Operator</label>
                          <Dropdown
                            v-model="param.compare2.modifyOperator"
                            :options="dropdowns.modifyDropdown"
                            optionLabel="label"
                            optionValue="value"
                            class="w-full"
                          />
                        </div>
                        <div
                          class="form-field mb-2"
                          v-if="param.compare2.modifyOperator"
                        >
                          <label>Modify Value</label>
                          <InputNumber
                            v-model="param.compare2.modifyValue"
                            class="w-full"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        (isEditing &&
                          param.compare3 &&
                          param.compare3?.field) ||
                        (!isEditing &&
                          param.compare1?.field &&
                          param.compare1.field !== 'NA')
                      "
                    >
                      <label>Compare Function 3</label>
                      <Dropdown
                        v-model="param.compareFunc3"
                        :options="dropdowns.compareFuncDropdown"
                        optionLabel="label"
                        optionValue="value"
                        class="w-full"
                      />
                    </div>
                    <div
                      v-if="
                        (isEditing &&
                          param.compare3 &&
                          param.compare3?.field) ||
                        (!isEditing &&
                          param.compare1?.field &&
                          param.compare1.field !== 'NA')
                      "
                    >
                      <div class="grid gap-4">
                        <div class="col-12 md:col-6">
                          <h4 class="mt-0">Compare 3</h4>
                          <div class="flex gap-3">
                            <div class="flex-1">
                              <label>Phase</label>
                              <Dropdown
                                v-model="param.compare3.phase"
                                :options="dropdowns.phaseDropdown"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select phase"
                                class="w-full"
                                :class="{
                                  'p-invalid': getParameterError(
                                    index,
                                    'phase3'
                                  ),
                                }"
                                @change="
                                  handlePhaseChange(
                                    param,
                                    'compare3',
                                    $event.value
                                  )
                                "
                              />
                              <small class="text-red-500">{{
                                getParameterError(index, "phase1")
                              }}</small>
                            </div>
                            <!-- Field Dropdown and Dynamic Input for Compare 1 (if phase is not static) -->
                            <template
                              v-if="
                                param.compare3.phase !== 'staticValue' &&
                                param.compare3.phase !== 'staticRange'
                              "
                            >
                              <div class="form-field mb-2">
                                <label>Field</label>
                                <Dropdown
                                  v-model="param.compare3.field"
                                  :options="
                                    getFieldOptions(param.compare3.phase)
                                  "
                                  optionLabel="label"
                                  optionValue="value"
                                  class="w-full"
                                  @change="
                                    handleFieldChange(
                                      param,
                                      'compare3',
                                      $event.value
                                    )
                                  "
                                />
                              </div>
                            </template>
                            <template
                              v-else-if="param.compare3.phase === 'staticValue'"
                            >
                              <div class="form-field mb-2">
                                <label>Value</label>
                                <InputText
                                  v-model="param.compare3.value1"
                                  class="w-full"
                                />
                              </div>
                            </template>
                            <template
                              v-else-if="param.compare3.phase === 'staticRange'"
                            >
                              <div class="form-field mb-2">
                                <label>Range Start</label>
                                <InputText
                                  v-model="param.compare3.value1"
                                  class="w-full"
                                />
                              </div>
                              <div class="form-field mb-2">
                                <label>Range End</label>
                                <InputText
                                  v-model="param.compare3.value2"
                                  class="w-full"
                                />
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        (isEditing && param.compare4) ||
                        (!isEditing &&
                          param.compare1?.field &&
                          param.compare1.field !== 'NA')
                      "
                    >
                      <div class="grid gap-4">
                        <div class="col-12 md:col-6">
                          <h4 class="mt-0">Compare 4</h4>
                          <div class="flex gap-3">
                            <div class="flex-1">
                              <label>Phase</label>
                              <Dropdown
                                v-model="param.compare4.phase"
                                :options="dropdowns.phaseDropdown"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select phase"
                                class="w-full"
                                :class="{
                                  'p-invalid': getParameterError(
                                    index,
                                    'phase4'
                                  ),
                                }"
                                @change="
                                  handlePhaseChange(
                                    param,
                                    'compare4',
                                    $event.value
                                  )
                                "
                              />
                              <small class="text-red-500">{{
                                getParameterError(index, "phase1")
                              }}</small>
                            </div>
                            <!-- Field Dropdown and Dynamic Input for Compare 1 (if phase is not static) -->
                            <template
                              v-if="
                                param.compare4.phase !== 'staticValue' &&
                                param.compare4.phase !== 'staticRange'
                              "
                            >
                              <div class="form-field mb-2">
                                <label>Field</label>
                                <Dropdown
                                  v-model="param.compare4.field"
                                  :options="
                                    getFieldOptions(param.compare4.phase)
                                  "
                                  optionLabel="label"
                                  optionValue="value"
                                  class="w-full"
                                  @change="
                                    handleFieldChange(
                                      param,
                                      'compare4',
                                      $event.value
                                    )
                                  "
                                />
                              </div>
                              <div
                                class="form-field"
                                v-if="
                                  param.compare3.field &&
                                  param.compare3.field !== 'NA'
                                "
                              >
                                <template
                                  v-if="
                                    getFieldConfig(
                                      param.compare3.phase,
                                      param.compare3.field
                                    ) &&
                                    getFieldConfig(
                                      param.compare3.phase,
                                      param.compare3.field
                                    ).input === Number
                                  "
                                >
                                  <label>
                                    {{
                                      capitalizeFirstLetters(
                                        getFieldConfig(
                                          param.compare3.phase,
                                          param.compare3.field
                                        )?.label || "Select Field"
                                      )
                                    }}
                                  </label>
                                  <InputNumber
                                    v-model="param.compare3.value"
                                    class="w-full"
                                  />
                                </template>
                                <template
                                  v-if="
                                    getFieldConfig(
                                      param.compare3.phase,
                                      param.compare3.field
                                    ) &&
                                    getFieldConfig(
                                      param.compare3.phase,
                                      param.compare3.field
                                    ).input === Date
                                  "
                                >
                                  <label>
                                    {{
                                      capitalizeFirstLetters(
                                        getFieldConfig(
                                          param.compare3.phase,
                                          param.compare3.field
                                        )?.label || "Select Field"
                                      )
                                    }}
                                  </label>
                                  <Calendar
                                    v-model="param.compare3.fieldValue"
                                    dateFormat="yy-mm-dd"
                                    class="w-full"
                                  />
                                </template>
                                <template
                                  v-if="
                                    getFieldConfig(
                                      param.compare3.phase,
                                      param.compare3.field
                                    ) &&
                                    getFieldConfig(
                                      param.compare3.phase,
                                      param.compare3.field
                                    ).input === String
                                  "
                                >
                                  <label>
                                    {{
                                      capitalizeFirstLetters(
                                        getFieldConfig(
                                          param.compare3.phase,
                                          param.compare3.field
                                        )?.label || "Select Field"
                                      )
                                    }}
                                  </label>
                                  <InputText
                                    v-model="param.compare3.fieldValue"
                                    class="w-full"
                                  />
                                </template>
                              </div>
                            </template>
                            <template
                              v-else-if="param.compare4.phase === 'staticValue'"
                            >
                              <div class="form-field mb-2">
                                <label>Value</label>
                                <InputText
                                  v-model="param.compare4.value1"
                                  class="w-full"
                                />
                              </div>
                            </template>
                            <template
                              v-else-if="param.compare4.phase === 'staticRange'"
                            >
                              <div class="form-field mb-2">
                                <label>Range Start</label>
                                <InputText
                                  v-model="param.compare4.value1"
                                  class="w-full"
                                />
                              </div>
                              <div class="form-field mb-2">
                                <label>Range End</label>
                                <InputText
                                  v-model="param.compare4.value2"
                                  class="w-full"
                                />
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- Count Type Fields -->
                  <div v-if="param.type === 'count'">
                    <div class="flex gap-4 mb-4">
                      <div class="flex-1">
                        <label>Minimum Count</label>
                        <InputNumber v-model="param.minvalue" class="w-full" />
                      </div>
                      <div class="flex-1">
                        <label>Maximum Count</label>
                        <InputNumber v-model="param.maxvalue" class="w-full" />
                      </div>
                      <div class="flex items-center mt-6">
                        <label>Consecutive Runs</label>
                        <Checkbox v-model="param.consecutive" :binary="true" />
                      </div>
                      <div class="flex items-center mt-6">
                        <label>If Exists</label>
                        <Checkbox v-model="param.ifExists" />
                      </div>
                    </div>
                    <div class="grid gap-4">
                      <div class="col-12 md:col-6">
                        <h4 class="mt-0">Compare 1</h4>
                        <div class="flex gap-3">
                          <div class="flex-1">
                            <label>Phase</label>
                            <Dropdown
                              v-model="param.compare1.phase"
                              :options="dropdowns.phaseDropdown"
                              optionLabel="label"
                              optionValue="value"
                              placeholder="Select phase"
                              class="w-full"
                              :class="{
                                'p-invalid': getParameterError(index, 'phase1'),
                              }"
                              @change="
                                handlePhaseChange(
                                  param,
                                  'compare1',
                                  $event.value
                                )
                              "
                            />
                            <small class="text-red-500">{{
                              getParameterError(index, "phase1")
                            }}</small>
                          </div>
                          <!-- Field Dropdown and Dynamic Input for Compare 1 (if phase is not static) -->
                          <template
                            v-if="
                              param.compare1.phase !== 'staticValue' &&
                              param.compare1.phase !== 'staticRange'
                            "
                          >
                            <div class="form-field mb-2">
                              <label>Field</label>
                              <Dropdown
                                v-model="param.compare1.field"
                                :options="getFieldOptions(param.compare1.phase)"
                                optionLabel="label"
                                optionValue="value"
                                class="w-full"
                                @change="
                                  handleFieldChange(
                                    param,
                                    'compare1',
                                    $event.value
                                  )
                                "
                              />
                            </div>
                            <div
                              class="form-field"
                              v-if="
                                param.compare1.field &&
                                param.compare1.field !== 'NA'
                              "
                            >
                              <!-- Dynamic Input based on field type -->
                              <template
                                v-if="
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  ) && param.compare2.phase !== 'staticValue'
                                "
                              >
                                <label>
                                  {{
                                    capitalizeFirstLetters(
                                      getFieldConfig(
                                        param.compare1.phase,
                                        param.compare1.field
                                      )?.label || "Select Field"
                                    )
                                  }}
                                </label>
                                <!-- Render InputText if field is a String -->
                                <InputText
                                  v-if="
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    ).input === String
                                  "
                                  v-model="param.compare1.fieldValue"
                                  class="w-full"
                                />
                                <!-- Render InputNumber if field is a Number -->
                                <InputNumber
                                  v-else-if="
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    ).input === Number
                                  "
                                  v-model="param.compare1.fieldValue"
                                  class="w-full"
                                />
                                <!-- Render Calendar if field is a Date -->
                                <Calendar
                                  v-else-if="
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    ).input === Date
                                  "
                                  v-model="param.compare1.fieldValue"
                                  dateFormat="yy-mm-dd"
                                  class="w-full"
                                />
                                <!-- Render Dropdown if field is defined as an array -->
                                <Dropdown
                                  v-else-if="
                                    Array.isArray(
                                      getFieldConfig(
                                        param.compare1.phase,
                                        param.compare1.field
                                      ).input
                                    )
                                  "
                                  v-model="param.compare1.fieldValue"
                                  :options="
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    ).input
                                  "
                                  optionLabel="label"
                                  optionValue="value"
                                  class="w-full"
                                />
                              </template>
                              <template
                                v-if="param.compare1.phase === 'dbForm'"
                              >
                                <div class="flex-1">
                                  <label>Number</label>
                                  <InputNumber
                                    v-model="param.compare1.number"
                                    class="w-full"
                                  />
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        (isEditing &&
                          param.compare3 &&
                          param.compare3?.field) ||
                        (!isEditing &&
                          param.compare1?.field &&
                          param.compare1.field !== 'NA')
                      "
                    >
                      <div class="grid gap-4">
                        <div class="col-12 md:col-6">
                          <h4 class="mt-0">Compare 3</h4>
                          <div class="flex gap-3">
                            <div class="flex-1">
                              <label>Phase</label>
                              <Dropdown
                                v-model="param.compare1.phase"
                                :options="dropdowns.phaseDropdown"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select phase"
                                class="w-full"
                                :class="{
                                  'p-invalid': getParameterError(
                                    index,
                                    'phase1'
                                  ),
                                }"
                                @change="
                                  handlePhaseChange(
                                    param,
                                    'compare1',
                                    $event.value
                                  )
                                "
                              />
                              <small class="text-red-500">{{
                                getParameterError(index, "phase1")
                              }}</small>
                            </div>
                            <!-- Field Dropdown and Dynamic Input for Compare 1 (if phase is not static) -->
                            <template
                              v-if="
                                param.compare1.phase !== 'staticValue' &&
                                param.compare1.phase !== 'staticRange'
                              "
                            >
                              <div class="form-field mb-2">
                                <label>Field</label>
                                <Dropdown
                                  v-model="param.compare1.field"
                                  :options="
                                    getFieldOptions(param.compare1.phase)
                                  "
                                  optionLabel="label"
                                  optionValue="value"
                                  class="w-full"
                                  @change="
                                    handleFieldChange(
                                      param,
                                      'compare1',
                                      $event.value
                                    )
                                  "
                                />
                              </div>
                              <div
                                class="form-field"
                                v-if="param.compare1.field"
                              >
                                <!-- Dynamic Input based on field type -->
                                <template
                                  v-if="
                                    getFieldConfig(
                                      param.compare1.phase,
                                      param.compare1.field
                                    ) && param.compare2.phase !== 'staticValue'
                                  "
                                >
                                  <label>
                                    {{
                                      capitalizeFirstLetters(
                                        getFieldConfig(
                                          param.compare1.phase,
                                          param.compare1.field
                                        )?.label || "Select Field"
                                      )
                                    }}
                                  </label>
                                  <!-- Render InputText if field is a String -->
                                  <InputText
                                    v-if="
                                      getFieldConfig(
                                        param.compare1.phase,
                                        param.compare1.field
                                      ).input === String
                                    "
                                    v-model="param.compare1.fieldValue"
                                    class="w-full"
                                  />
                                  <!-- Render InputNumber if field is a Number -->
                                  <InputNumber
                                    v-else-if="
                                      getFieldConfig(
                                        param.compare1.phase,
                                        param.compare1.field
                                      ).input === Number
                                    "
                                    v-model="param.compare1.fieldValue"
                                    class="w-full"
                                  />
                                  <!-- Render Calendar if field is a Date -->
                                  <Calendar
                                    v-else-if="
                                      getFieldConfig(
                                        param.compare1.phase,
                                        param.compare1.field
                                      ).input === Date
                                    "
                                    v-model="param.compare1.fieldValue"
                                    dateFormat="yy-mm-dd"
                                    class="w-full"
                                  />
                                  <!-- Render Dropdown if field is defined as an array -->
                                  <Dropdown
                                    v-else-if="
                                      Array.isArray(
                                        getFieldConfig(
                                          param.compare1.phase,
                                          param.compare1.field
                                        ).input
                                      )
                                    "
                                    v-model="param.compare1.fieldValue"
                                    :options="
                                      getFieldConfig(
                                        param.compare1.phase,
                                        param.compare1.field
                                      ).input
                                    "
                                    optionLabel="label"
                                    optionValue="value"
                                    class="w-full"
                                  />
                                </template>
                                <template
                                  v-if="param.compare1.phase === 'dbForm'"
                                >
                                  <div class="flex-1">
                                    <label>Number</label>
                                    <InputNumber
                                      v-model="param.compare1.number"
                                      class="w-full"
                                    />
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        (isEditing && param.compare4) ||
                        (!isEditing &&
                          param.compare1?.field &&
                          param.compare1.field !== 'NA')
                      "
                    >
                      <div class="grid gap-4">
                        <div class="col-12 md:col-6">
                          <h4 class="mt-0">Compare 4</h4>
                          <div class="flex gap-3">
                            <div class="flex-1">
                              <label>Phase</label>
                              <Dropdown
                                v-model="param.compare4.phase"
                                :options="dropdowns.phaseDropdown"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select phase"
                                class="w-full"
                                :class="{
                                  'p-invalid': getParameterError(
                                    index,
                                    'phase4'
                                  ),
                                }"
                                @change="
                                  handlePhaseChange(
                                    param,
                                    'compare4',
                                    $event.value
                                  )
                                "
                              />
                              <small class="text-red-500">{{
                                getParameterError(index, "phase4")
                              }}</small>
                            </div>
                            <template
                              v-if="
                                param.compare4.phase !== 'staticValue' &&
                                param.compare4.phase !== 'staticRange'
                              "
                            >
                              <div class="form-field mb-2">
                                <label>Field</label>
                                <Dropdown
                                  v-model="param.compare4.field"
                                  :options="
                                    getFieldOptions(param.compare4.phase)
                                  "
                                  optionLabel="label"
                                  optionValue="value"
                                  class="w-full"
                                  @change="
                                    handleFieldChange(
                                      param,
                                      'compare4',
                                      $event.value
                                    )
                                  "
                                />
                              </div>
                              <div
                                class="form-field"
                                v-if="
                                  param.compare4.field &&
                                  param.compare4.field !== 'NA'
                                "
                              >
                                <!-- Dynamic Input based on field type -->
                                <template
                                  v-if="
                                    getFieldConfig(
                                      param.compare4.phase,
                                      param.compare4.field
                                    ) && param.compare4.phase !== 'staticValue'
                                  "
                                >
                                  <label>
                                    {{
                                      capitalizeFirstLetters(
                                        getFieldConfig(
                                          param.compare4.phase,
                                          param.compare4.field
                                        )?.label || "Select Field"
                                      )
                                    }}
                                  </label>
                                  <!-- Render InputText if field is a String -->
                                  <InputText
                                    v-if="
                                      getFieldConfig(
                                        param.compare4.phase,
                                        param.compare4.field
                                      ).input === String
                                    "
                                    v-model="param.compare4.fieldValue"
                                    class="w-full"
                                  />
                                  <!-- Render InputNumber if field is a Number -->
                                  <InputNumber
                                    v-else-if="
                                      getFieldConfig(
                                        param.compare4.phase,
                                        param.compare4.field
                                      ).input === Number
                                    "
                                    v-model="param.compare4.fieldValue"
                                    class="w-full"
                                  />
                                  <!-- Render Calendar if field is a Date -->
                                  <Calendar
                                    v-else-if="
                                      getFieldConfig(
                                        param.compare4.phase,
                                        param.compare4.field
                                      ).input === Date
                                    "
                                    v-model="param.compare4.fieldValue"
                                    dateFormat="yy-mm-dd"
                                    class="w-full"
                                  />
                                  <!-- Render Dropdown if field is defined as an array -->
                                  <Dropdown
                                    v-else-if="
                                      Array.isArray(
                                        getFieldConfig(
                                          param.compare4.phase,
                                          param.compare4.field
                                        ).input
                                      )
                                    "
                                    v-model="param.compare4.fieldValue"
                                    :options="
                                      getFieldConfig(
                                        param.compare4.phase,
                                        param.compare4.field
                                      ).input
                                    "
                                    optionLabel="label"
                                    optionValue="value"
                                    class="w-full"
                                  />
                                </template>
                                <template
                                  v-if="param.compare4.phase === 'dbForm'"
                                >
                                  <div class="flex-1">
                                    <label>Number</label>
                                    <InputNumber
                                      v-model="param.compare4.number"
                                      class="w-full"
                                    />
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Percent Type Fields -->
                  <template v-if="param.type === 'percent'">
                    <div class="flex gap-4 mb-4">
                      <div class="flex-1">
                        <label>Target Percentage</label>
                        <InputNumber
                          v-model="param.percentTarget"
                          mode="decimal"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          class="w-full"
                        />
                      </div>
                      <div class="flex-1">
                        <label>Percent Compare</label>
                        <Dropdown
                          v-model="param.percentCompare"
                          :options="percentCompareOptions"
                          optionLabel="label"
                          optionValue="value"
                          class="w-full"
                        />
                      </div>
                    </div>
                    <div class="flex gap-3">
                      <div class="flex-1">
                        <label>Compare 1</label>
                        <Dropdown
                          v-model="param.compare1.phase"
                          :options="dropdowns.phaseDropdown"
                          optionLabel="label"
                          optionValue="value"
                          placeholder="Select phase"
                          class="w-full"
                          :class="{
                            'p-invalid': getParameterError(index, 'phase1'),
                          }"
                          @change="
                            handlePhaseChange(param, 'compare1', $event.value)
                          "
                        />
                      </div>
                      <template
                        v-if="
                          param.compare1.phase !== 'staticValue' &&
                          param.compare1.phase !== 'staticRange'
                        "
                      >
                        <div class="form-field mb-2">
                          <label>Field</label>
                          <Dropdown
                            v-model="param.compare1.field"
                            :options="getFieldOptions(param.compare1.phase)"
                            optionLabel="label"
                            optionValue="value"
                            class="w-full"
                            @change="
                              handleFieldChange(param, 'compare1', $event.value)
                            "
                          />
                        </div>
                        <div
                          class="form-field"
                          v-if="
                            param.compare1.field &&
                            param.compare1.field !== 'NA'
                          "
                        >
                          <!-- Dynamic Input based on field type -->
                          <template
                            v-if="
                              getFieldConfig(
                                param.compare1.phase,
                                param.compare1.field
                              ) && param.compare2.phase !== 'staticValue'
                            "
                          >
                            <label>
                              {{
                                capitalizeFirstLetters(
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  )?.label || "Select Field"
                                )
                              }}
                            </label>
                            <!-- Render InputText if field is a String -->
                            <InputText
                              v-if="
                                getFieldConfig(
                                  param.compare1.phase,
                                  param.compare1.field
                                ).input === String
                              "
                              v-model="param.compare1.fieldValue"
                              class="w-full"
                            />
                            <!-- Render InputNumber if field is a Number -->
                            <InputNumber
                              v-else-if="
                                getFieldConfig(
                                  param.compare1.phase,
                                  param.compare1.field
                                ).input === Number
                              "
                              v-model="param.compare1.fieldValue"
                              class="w-full"
                            />
                            <!-- Render Calendar if field is a Date -->
                            <Calendar
                              v-else-if="
                                getFieldConfig(
                                  param.compare1.phase,
                                  param.compare1.field
                                ).input === Date
                              "
                              v-model="param.compare1.fieldValue"
                              dateFormat="yy-mm-dd"
                              class="w-full"
                            />
                            <!-- Render Dropdown if field is defined as an array -->
                            <Dropdown
                              v-else-if="
                                Array.isArray(
                                  getFieldConfig(
                                    param.compare1.phase,
                                    param.compare1.field
                                  ).input
                                )
                              "
                              v-model="param.compare1.fieldValue"
                              :options="
                                getFieldConfig(
                                  param.compare1.phase,
                                  param.compare1.field
                                ).input
                              "
                              optionLabel="label"
                              optionValue="value"
                              class="w-full"
                            />
                          </template>
                          <template v-if="param.compare1.phase === 'dbForm'">
                            <div class="flex-1">
                              <label>Number</label>
                              <InputNumber
                                v-model="param.compare1.number"
                                class="w-full"
                              />
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                    <div class="form-field mb-2">
                      <label>Compare Function</label>
                      <Dropdown
                        v-model="param.compareFunc"
                        :options="dropdowns.compareFuncDropdown"
                        optionLabel="label"
                        optionValue="value"
                        class="w-full"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <Button
              label="Add Parameter"
              icon="pi pi-plus"
              class="p-button-outlined w-full"
              @click="addParameter"
            />
          </div>
          <!-- Step 3: Sentences -->
          <div v-if="currentStep === 2">
            <!-- Sticky header container -->
            <div class="sticky top-0 bg-white z-10 p-4">
              <div class="flex">
                <div class="w-2/3">Sentence /</div>
                <div class="w-1/3 text-center">Order / Use Count / Delete</div>
              </div>
            </div>

            <!-- Scrollable content -->
            <div class="overflow-auto max-h-[500px]">
              <Draggable
                v-model="sortedSentences"
                @end="updateOrder"
                handle=".drag-handle"
                class="sentences-container"
                item-key="id"
              >
                <template #item="{ element, index }">
                  <div :key="element.id" class="sentence-item mb-4 px-4">
                    <div class="flex">
                      <!-- Left side: Drag handle and Sentence input (66.67%) -->
                      <div class="w-2/3 flex items-center space-x-2">
                        <span class="drag-handle cursor-move">
                          <i class="pi pi-bars"></i>
                        </span>
                        <div class="flex-grow">
                          <Textarea
                            v-model="element.sentence"
                            placeholder="Enter sentence"
                            class="w-full"
                            rows="3"
                            autoResize
                            :class="{
                              'p-invalid': getSentenceError(index, 'sentence'),
                            }"
                          />
                          <small class="text-red-500">
                            {{ getSentenceError(index, "sentence") }}
                          </small>
                        </div>
                      </div>

                      <!-- Right side: Order, Use Count, and Delete button (33.33%) -->
                      <div class="w-1/3 flex items-center space-x-3 pl-4">
                        <div class="w-1/3">
                          <InputNumber
                            v-model="element.order"
                            placeholder="Order"
                            class="w-full"
                            :class="{
                              'p-invalid': getSentenceError(index, 'order'),
                            }"
                          />
                          <small class="text-red-500">
                            {{ getSentenceError(index, "order") }}
                          </small>
                        </div>
                        <div class="w-1/3">
                          <InputNumber
                            v-model="element.usecount"
                            placeholder="Use Count"
                            class="w-full"
                          />
                        </div>
                        <div class="w-1/3 flex justify-center">
                          <Button
                            icon="pi pi-trash"
                            class="p-button-danger p-button-outlined"
                            @click="removeSentence(index)"
                            :disabled="formData.sentences.length === 1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Draggable>
            </div>

            <!-- Add Sentence button -->
            <Button
              label="Add Sentence"
              icon="pi pi-plus"
              class="p-button-outlined mt-2"
              @click="addSentence"
            />
          </div>
          <div v-if="currentStep === 3" class="step-content">
            <div class="review-section">
              <h3>Basic Information</h3>
              <div class="review-grid">
                <div>
                  <strong>Group:</strong> {{ formData.basic.groupName }}
                </div>
                <div><strong>Name:</strong> {{ formData.basic.name }}</div>
                <div><strong>Order:</strong> {{ formData.basic.order }}</div>
                <div>
                  <strong>Direction:</strong>
                  {{ getDirectionText(formData.basic.direction) }}
                </div>
                <div>
                  <strong>Status:</strong>
                  {{ formData.basic.active ? "Active" : "Inactive" }}
                </div>
                <div>
                  <strong>Description:</strong> {{ formData.basic.description }}
                </div>
              </div>

              <h3 class="mt-4">Parameters</h3>
              <div
                v-for="(param, index) in formData.parameters"
                :key="index"
                class="mb-2"
              >
                {{ translateParam(param) }}
              </div>

              <h3 class="mt-4">Sentences</h3>
              <div
                v-for="(sentence, index) in formData.sentences"
                :key="index"
                class="mb-2"
              >
                {{ index + 1 }}. {{ sentence.sentence }}
              </div>
            </div>
          </div>
          <!-- Navigation Buttons -->
          <div class="flex justify-between gap-4 mt-4">
            <Button
              v-if="isEditing"
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="cancelEdit"
            />
            <Button
              label="Previous"
              icon="pi pi-chevron-left"
              class="p-button-secondary"
              @click="previousStep"
              :disabled="currentStep === 0"
            />

            <Button
              v-if="currentStep < 3"
              label="Next"
              icon="pi pi-chevron-right"
              iconPos="right"
              @click="nextStep"
            />

            <Button
              v-else
              :label="isEditing ? 'Update Rule' : 'Create Rule'"
              icon="pi pi-check"
              @click="createRule"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, reactive, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { API } from "aws-amplify";
import Card from "primevue/card";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import Steps from "primevue/steps";
import Draggable from "vuedraggable";
import { dropdowns } from "./param_options";
import Calendar from "primevue/calendar";

import {
  currentHorse_map,
  currentRace_map,
  currentMeeting_map,
  dbForm_map,
  dbHorse_map,
} from "./dataset_maps";
const isEditing = ref(false);
const currentRuleId = ref(null);
const searchQuery = ref("");
const matchedRuleIds = ref(new Set());
const toast = useToast();
const currentStep = ref(0);
const steps = ref([
  { label: "Basic Info", to: "#" },
  { label: "Parameters", to: "#" },
  { label: "Sentences", to: "#" },
  { label: "Review", to: "#" },
]);
const capitalizeFirstLetters = (str) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const getFieldOptions = (phase) => {
  let options = [];
  switch (phase) {
    case "currentHorse":
      options = [...currentHorse_map, { label: "NA", value: "NA" }];
      break;
    case "currentRace":
      options = [...currentRace_map, { label: "NA", value: "NA" }];
      break;
    case "currentMeeting":
      options = [...currentMeeting_map, { label: "NA", value: "NA" }];
      break;
    case "dbForm":
      options = [...dbForm_map, { label: "NA", value: "NA" }];
      break;
    case "dbHorse":
      options = [...dbHorse_map, { label: "NA", value: "NA" }];
      break;
    case "dbTrialForm":
      options = [...dbForm_map, { label: "NA", value: "NA" }];
      break;
    default:
      options = [{ label: "NA", value: "NA" }];
  }
  if (!Array.isArray(options)) {
    console.warn(`No options found for phase: ${phase}`);
    return [];
  }

  // Format options for dropdown
  return options.map((option) => ({
    label: option.label,
    value: option.value,
    input: option.input,
  }));
};
const handleFieldDependencies = (param, compareKey) => {
  const phase = param[compareKey].phase;
  const field = param[compareKey].field;

  // Get field configuration
  const fieldOptions = getFieldOptions(phase);
  const fieldConfig = fieldOptions.find((f) => f.value === field);

  if (fieldConfig) {
    // Update field-specific flags
    param[compareKey].distance = fieldConfig.distance || false;
    param[compareKey].date = fieldConfig.input === Date;
    param[compareKey].condition = fieldConfig.condition || false;
    param[compareKey].class = fieldConfig.input === String;

    // Handle input type specific setup
    if (
      typeof fieldConfig.input === "object" &&
      Array.isArray(fieldConfig.input)
    ) {
      param[compareKey].fieldOptions = fieldConfig.input;
    } else {
      param[compareKey].fieldOptions = null;
    }
  }

  console.log("Updated field dependencies:", {
    phase,
    field,
    config: fieldConfig,
  });
};
const defaultParameter = {
  type: "compare",
  compare1: {
    phase: "dbForm",
    field: "",
    fieldValue: "",
    number: null,
    modifyOperator: null,
    modifyValue: null,
    isJumps: false,
    distance: false,
    date: false,
    condition: false,
    class: false,
  },
  compare2: {
    phase: "staticValue",
    value: "",
    field: "",
    fieldValue: "",
    value1: "",
    value2: "",
    modifyOperator: null,
    modifyValue: null,
  },
  compare3: {
    phase: "staticValue",
    value: "",
    field: "",
    fieldValue: "",
    value1: "",
    value2: "",
    modifyOperator: null,
    modifyValue: null,
  },
  compare4: {
    phase: "staticValue",
    value: "",
    field: "",
    fieldValue: "",
    value1: "",
    value2: "",
    modifyOperator: null,
    modifyValue: null,
  },
  compareFunc: "equalTo",
  compareFunc3: "equalTo",
  ifExists: false,
  consecutive: false,
  campaign: null,
  campaignrun: null,
  campaignrunexclude: null,
  minvalue: null,
  maxvalue: null,
  percentTarget: null,
  percentCompare: null,
};
const formData = reactive({
  basic: {
    groupName: "",
    name: "",
    order: 0,
    description: "",
    active: true,
    direction: 1,
  },
  parameters: [{ ...defaultParameter }],
  sentences: [
    {
      sentence: "",
      order: 1,
      usecount: 0,
    },
  ],
});

const percentCompareOptions = computed(() => [
  { label: "Greater Than", value: "greaterThan" },
  { label: "Less Than", value: "lessThan" },
]);

const groupedComments = ref([]);
const openTabs = ref(["0"]);
const searchResults = computed(() => {
  if (!searchQuery.value) {
    return {
      groups: groupedComments.value,
      matchedRuleIds: new Set(),
      matchedGroupIndexes: new Set(),
    };
  }

  const query = searchQuery.value.toLowerCase();
  const newMatchedRuleIds = new Set();
  const matchedGroupIndexes = new Set();

  const filtered = groupedComments.value
    .map((group, groupIndex) => {
      const matchedRules = group.rules.filter((rule) => {
        const matches =
          rule.name.toLowerCase().includes(query) ||
          rule.description.toLowerCase().includes(query);

        if (matches) {
          newMatchedRuleIds.add(rule._id);
          matchedGroupIndexes.add(groupIndex.toString());
        }
        return matches;
      });

      return {
        ...group,
        rules: matchedRules,
      };
    })
    .filter((group) => group.rules.length > 0);

  return {
    groups: filtered,
    matchedRuleIds: newMatchedRuleIds,
    matchedGroupIndexes: matchedGroupIndexes,
  };
});
const filteredGroups = computed(() => {
  return searchResults.value.groups;
});
const sortedSentences = computed({
  get: () => {
    return [...formData.sentences].sort((a, b) => a.order - b.order);
  },
  set: (newValue) => {
    formData.sentences = newValue;
  },
});
const isNewGroup = ref(false);
const handleGroupNameChange = (newName) => {
  // Check if this name already exists (case-insensitive check recommended)
  const exists = filteredGroups.value.some(
    (option) => option.name.toLowerCase() === newName.toLowerCase()
  );
  if (!exists) {
    isNewGroup.value = true;
  }
};

const expandedParameters = ref([]);
watch(
  () => searchResults.value,
  (newResults) => {
    matchedRuleIds.value = newResults.matchedRuleIds;
    console.log("Matched Rule IDs:", matchedRuleIds.value);
    // Handle open tabs based on search state
    if (searchQuery.value) {
      // If searching, open tabs with matches
      if (newResults.matchedGroupIndexes.size > 0) {
        openTabs.value = Array.from(newResults.matchedGroupIndexes);
        console.log("Matched Group Indexes:", newResults.matchedGroupIndexes);
      }
    } else {
      // If search is cleared, close all tabs
      openTabs.value = [];
    }
  },
  { deep: true }
);
watch(
  () => formData.basic.groupName,
  (newValue) => {
    handleGroupNameChange(newValue);
    if (filteredGroups.value.some((group) => group.name === newValue)) {
      isNewGroup.value = null;
    }
  },
  { deep: true }
);
const toggleParameter = (index) => {
  if (expandedParameters.value.includes(index)) {
    // Remove the index to collapse the parameter
    expandedParameters.value = expandedParameters.value.filter(
      (i) => i !== index
    );
  } else {
    // Add the index to expand the parameter
    expandedParameters.value.push(index);
  }
};
const removeParameter = (index) => {
  formData.parameters.splice(index, 1);
  expandedParameters.value = expandedParameters.value.filter(
    (i) => i !== index
  );
};
const isValidStep = (newStep) => {
  console.log("Validating step:", newStep);
  return true; // Return false to block navigation
};
const handleStepClick = (item) => {
  if (isValidStep(item)) {
    const index = steps.value.findIndex((step) => step.label === item.label);
    if (index !== -1) {
      currentStep.value = index;
    }
  }
};
// Add these to your existing reactive declarations
const stepErrors = reactive({
  basic: {
    groupName: "",
    name: "",
    description: "",
    order: "",
  },
  parameters: [],
  sentences: [],
});

// Update the validateCurrentStep function
const validateCurrentStep = () => {
  let isValid = true;

  // Reset errors for current step
  if (currentStep.value === 0) {
    Object.keys(stepErrors.basic).forEach(
      (key) => (stepErrors.basic[key] = "")
    );
  }

  switch (currentStep.value) {
    case 0: // Basic Information
      if (!formData.basic.groupName?.trim()) {
        stepErrors.basic.groupName = "Group name is required";
        isValid = false;
      }

      if (!formData.basic.name?.trim()) {
        stepErrors.basic.name = "Rule name is required";
        isValid = false;
      }

      if (!formData.basic.description?.trim()) {
        stepErrors.basic.description = "Description is required";
        isValid = false;
      }

      if (
        typeof formData.basic.order !== "number" ||
        formData.basic.order < 0
      ) {
        stepErrors.basic.order = "Order must be a positive number";
        isValid = false;
      }
      break;

    case 1: // Parameters
      stepErrors.parameters = [];
      formData.parameters.forEach((param, index) => {
        const paramErrors = {};

        if (param.type === "compare") {
          if (!param.compare1.phase) {
            paramErrors.phase1 = "Phase is required";
            isValid = false;
          }
          if (param.compare1.phase !== "staticValue" && !param.compare1.field) {
            paramErrors.field1 = "Field is required";
            isValid = false;
          }
          if (!param.compareFunc) {
            paramErrors.compareFunc = "Compare function is required";
            isValid = false;
          }
          if (
            param.compare2.phase === "staticValue" &&
            !param.compare2.value &&
            param.compare2.value !== 0
          ) {
            paramErrors.value2 = "Value is required";
            isValid = false;
          }
        } else if (param.type === "count") {
          if (!param.minvalue && param.minvalue !== 0) {
            paramErrors.minvalue = "Minimum value is required";
            isValid = false;
          }
          if (!param.maxvalue && param.maxvalue !== 0) {
            paramErrors.maxvalue = "Maximum value is required";
            isValid = false;
          }
        } else if (param.type === "percent") {
          if (!param.percentTarget && param.percentTarget !== 0) {
            paramErrors.percentTarget = "Target percentage is required";
            isValid = false;
          }
          if (!param.percentCompare) {
            paramErrors.percentCompare = "Comparison type is required";
            isValid = false;
          }
        }

        stepErrors.parameters[index] = paramErrors;
      });
      break;

    case 2: // Sentences
      stepErrors.sentences = [];
      formData.sentences.forEach((sentence, index) => {
        const sentenceErrors = {};

        if (!sentence.sentence?.trim()) {
          sentenceErrors.sentence = "Sentence text is required";
          isValid = false;
        }

        if (typeof sentence.order !== "number" || sentence.order < 1) {
          sentenceErrors.order = "Valid order is required";
          isValid = false;
        }

        stepErrors.sentences[index] = sentenceErrors;
      });
      break;

    case 3: // Review
      // Review step doesn't need validation
      isValid = true;
      break;
  }

  // Update step validation status
  steps.value[currentStep.value].validationStatus = isValid
    ? "valid"
    : "invalid";

  return isValid;
};

const nextStep = () => {
  if (validateCurrentStep()) {
    steps.value[currentStep.value].validationStatus = "valid";
    if (currentStep.value < steps.value.length - 1) {
      currentStep.value++;
    }
  } else {
    steps.value[currentStep.value].validationStatus = "invalid";
    // Show error toast
    toast.add({
      severity: "error",
      summary: "Validation Error",
      detail: "Please fix the highlighted errors before proceeding",
      life: 3000,
    });
  }
};

const getParameterError = (paramIndex, field) => {
  return stepErrors.parameters[paramIndex]?.[field] || "";
};

const getSentenceError = (sentenceIndex, field) => {
  return stepErrors.sentences[sentenceIndex]?.[field] || "";
};
const getStepIndex = (item) => {
  return steps.value.findIndex((step) => step.label === item.label);
};
const previousStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};

const handleSearch = () => {
  console.log("Searching for:", searchQuery.value);
  // If search is cleared, reset matched IDs
  if (!searchQuery.value) {
    matchedRuleIds.value = new Set();
  }
};
const updateOpenTabs = (newValue) => {
  // Only update if we're not in search mode
  if (!searchQuery.value) {
    openTabs.value = newValue;
  }
};
const isRuleMatched = (rule) => {
  return matchedRuleIds.value.has(rule._id);
};
const getAllComments = async () => {
  try {
    const response = await API.get("MrCenApiGateway", "/comments?all=true", {});
    console.log("API Response:", response);
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error("Error fetching comments:", error);
    return [];
  }
};
// Add a cancel edit function
const cancelEdit = () => {
  isEditing.value = false;
  currentRuleId.value = null;
  resetForm();
  currentStep.value = 0;
};

const loadAndGroupComments = async () => {
  try {
    const rawItems = await getAllComments();
    if (!Array.isArray(rawItems)) {
      console.error("API response is not an array:", rawItems);
      return;
    }

    groupedComments.value = rawItems
      .map((item) => ({
        name: item.name || "Uncategorized",
        rules: Array.isArray(item.rules)
          ? [...item.rules].sort((a, b) => (a.order || 0) - (b.order || 0))
          : [],
        id: item._id,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    console.log("Loaded and grouped comments:", groupedComments.value);
  } catch (error) {
    console.error("Error loading and grouping comments:", error);
  }
};
const onUpdateRule = async (rule) => {
  console.log("onUpdateRule called with rule:", rule);
  isEditing.value = true;
  currentRuleId.value = rule.groupName;
  console.log("Current rule ID:", groupedComments.value);
  // Find the group containing this rule
  const group = groupedComments.value.find((g) =>
    g.rules.some((r) => r.name === rule.name)
  );
  console.log("Group found:", group);
  // Populate form data
  formData.basic = {
    groupName: group?.name || "",
    name: rule.name,
    order: rule.order,
    description: rule.description,
    active: rule.active,
    direction: rule.direction,
  };
  formData.parameters = rule.ruleParameters
    ? JSON.parse(JSON.stringify(rule.ruleParameters))
    : [];
  formData.sentences = rule.sentences
    ? JSON.parse(JSON.stringify(rule.sentences))
    : [];
  currentStep.value = 0;
};

const getDirectionClass = (direction) => {
  switch (direction) {
    case 1:
      return "direction-positive";
    case -1:
      return "direction-negative";
    default:
      return "direction-neutral";
  }
};

const getDirectionText = (direction) => {
  switch (direction) {
    case 1:
      return "Positive";
    case -1:
      return "Negative";
    default:
      return "Neutral";
  }
};

const translateTerms = (term,phase = "") => {
  if (term === "") return "";
  const translationKey = {
    compare: "Compare: ",
    count: "Count: ",
    currentHorse: "upcoming horse",
    currentRace: "upcoming race",
    currentMeeting: "upcoming meeting",
    dbHorse: "horse db entry",
    dbForm: "horse form",
    dbTrialForm: "horse form, including trials ",
    staticValue: "",
    lessThan: "is less than ",
    greaterThan: "is greater than ",
    equalTo: "is equal to ",
    notEqualTo: "is not equal to ",
    "*": "times ",
    "/": "divided by ",
    "-": "minus ",
    "+": "plus ",
  };
  try {
    switch (phase) {
      case "dbForm":
        return (dbForm_map.find((t) => t.value === term).label ?? term);
      case "dbHorse":
        return (dbHorse_map.find((t) => t.value === term).label ?? term);
      case "currentMeeting":
        return (currentMeeting_map.find((t) => t.value === term).label ?? term);
      case "currentRace":
        return (currentRace_map.find((t) => t.value === term).label ?? term);
      case "currentHorse":
        return (currentHorse_map.find((t) => t.value === term).label ?? term);
      default:
        if (translationKey[term] !== undefined) return translationKey[term];
        return term;
    }
  } catch (err){
    console.log(phase);
    console.log(term);
    console.log(err);
    return term;
  }
};
const translateParam = (param) => {
  let paramstring = "In " + translateTerms(param.compare1.phase) + ", ";
  if (param.campaign) paramstring += "in " + param.campaign + " campaign, ";
  if (param.campaignrun) paramstring += param.campaignrun + " up, ";
  if (param.campaignrunexclude)
    paramstring += "excluding " + param.campaignrunexclude + " up, ";
  if (param.compare1.isJumps !== undefined)
    paramstring +=
      "where " + (param.compare1.isJumps ? "is" : "is not") + " a jumps race, ";
  if (param.compare1.number !== undefined)
    paramstring += "run " + param.compare1.number + ", ";
  if (param.ifExists)
    paramstring += "(if form run exists is " + param.ifExists + "), ";
  if (param.compare3 && param.compare3.field)
    paramstring +=
      "and where " +
      translateTerms(param.compare3.field, param.compare3.phase) +
      (param.compare3.modifyOperator
        ? " " + param.compare3.modifyOperator
        : "") +
      (param.compare3.modifyValue ? " " + param.compare3.modifyValue : "") +
      " ";
  if (param.compareFunc3) paramstring += translateTerms(param.compareFunc3);
  if (param.compare4 && param.compare4.field)
    paramstring +=
      translateTerms(param.compare4.field, param.compare4.phase) +
      (param.compare4.modifyOperator
        ? " " + param.compare4.modifyOperator
        : "") +
      (param.compare4.modifyValue ? " " + param.compare4.modifyValue : "") +
      ", ";
  if (param.compare4 && param.compare4.value !== undefined)
    paramstring += param.compare4.value + ", ";
  if (param.compare4 && param.compare4.value1 !== undefined && param.compare4.value2 !== undefined)
    paramstring +=
      param.compare4.value1 + " and " + param.compare4.value2 + ", ";
  paramstring += param.type.toUpperCase() + ": ";
  if (param.minvalue !== undefined)
    paramstring += "a minimum of " + param.minvalue + " ";
  if (param.maxvalue !== undefined)
    paramstring += "a maximum of " + param.maxvalue + " ";
  if (param.consecutive) paramstring += "consecutive ";
  if (param.type === "count") paramstring += "form items ";
  if (param.compare2) paramstring += "where ";
  if (param.compare1.field !== undefined)
    paramstring +=
      translateTerms(param.compare1.field, param.compare1.phase) +
      (param.compare1.modifyOperator
        ? " " + param.compare1.modifyOperator
        : "") +
      (param.compare1.modifyValue ? " " + param.compare1.modifyValue : "") +
      " ";
  if (param.compareFunc) paramstring += translateTerms(param.compareFunc);
  if (param.compare2 && param.compare2.field)
    paramstring +=
      translateTerms(param.compare2.field, param.compare2.phase) +
      (param.compare2.modifyOperator
        ? " " + param.compare2.modifyOperator
        : "") +
      (param.compare2.modifyValue ? " " + param.compare2.modifyValue : "");
  if (param.compare2 && param.compare2.value !== undefined)
    paramstring += param.compare2.value;
  if (param.compare2 && param.compare2.value1 !== undefined && param.compare2.value2 !== undefined)
    paramstring += param.compare2.value1 + " and " + param.compare2.value2;
  if (param.compare2 && param.compare2.number !== undefined)
    paramstring += " in run " + param.compare2.number;
  if (param.compare2 && translateTerms(param.compare2.phase))
    paramstring += " of " + translateTerms(param.compare2.phase);
  if (param.percentCompare)
    paramstring += ", " + translateTerms(param.percentCompare);
  if (param.percentTarget)
    paramstring += (param.percentTarget * 100).toString() + "%";
  paramstring += ".";
  return paramstring;
};

onMounted(async () => {
  await loadAndGroupComments();
});

const addSentence = () => {
  const newId = (formData.sentences.length + 1).toString();
  formData.sentences.push({
    id: newId,
    sentence: "",
    order: formData.sentences.length + 1,
    usecount: 0,
  });
};

const updateOrder = () => {
  formData.sentences.forEach((sentence, index) => {
    sentence.order = index + 1;
  });
};
const removeSentence = (index) => {
  if (formData.sentences.length > 1) {
    formData.sentences.splice(index, 1);
    updateOrder();
  }
};

const addParameter = () => {
  const newParam = JSON.parse(JSON.stringify(defaultParameter));
  formData.parameters.push(newParam);
  expandedParameters.value.push(formData.parameters.length - 1);
};

const handleParameterTypeChange = (param, newType) => {
  param.type = newType;

  // Get the inputs for this type from expandInputs
  dropdowns.typeDropdown.find((t) => t.value === newType)?.inputs || [];

  // Reset and initialize fields based on type
  if (newType === "compare") {
    param.compare1 = { phase: "dbForm", field: "" };
    param.compare2 = { phase: "staticValue", value: "" };
    param.compare3 = { phase: "dbForm", field: "" };
    param.compareFunc = "equalTo";
    param.compareFunc1 = "equalTo";
    param.compareFunc2 = "equalTo";
  } else if (newType === "count") {
    param.minvalue = null;
    param.maxvalue = null;
    param.consecutive = false;
  } else if (newType === "percent") {
    param.percentTarget = null;
    param.percentCompare = null;
    param.campaign = null;
  }
};

const handlePhaseChange = (param, compareKey, newPhase) => {
  param[compareKey].phase = newPhase;
  param[compareKey].field = "";

  // Reset field-specific properties
  param[compareKey].number = null;
  param[compareKey].modifyOperator = null;
  param[compareKey].modifyValue = null;
  param[compareKey].isJumps = false;
  param[compareKey].distance = false;
  param[compareKey].date = false;
  param[compareKey].condition = false;
  param[compareKey].class = false;

  // Handle static value types
  if (newPhase === "staticValue") {
    param[compareKey].value = "";
  } else if (newPhase === "staticRange") {
    param[compareKey].value1 = "";
    param[compareKey].value2 = "";
  }

  // Get available input fields for this phase
  const phaseConfig = dropdowns.phaseDropdown.find((p) => p.value === newPhase);
  if (phaseConfig?.inputs) {
    console.log("Available inputs for phase:", phaseConfig.inputs);
  }
};
const datasetMaps = {
  dbForm: dbForm_map,
  currentRace: currentRace_map,
  currentMeeting: currentMeeting_map,
  currentHorse: currentHorse_map,
  dbHorse: dbHorse_map,
};

const getFieldConfig = (phase, field) => {
  const map = datasetMaps[phase] || [];
  return map.find((config) => config.value === field) || null;
};
const handleFieldChange = (param, compareKey, newField) => {
  param[compareKey].field = newField;
  handleFieldDependencies(param, compareKey);
  // Find the field configuration from dataset_maps
  const fieldMap = getFieldOptions(param[compareKey].phase);
  const fieldConfig = fieldMap.find((f) => f.value === newField);
  if (newField === "NA") {
    param.compareFunc3 = null;
    param.compare3 = { phase: null, field: null };
    param.compare4 = { phase: null, field: null };
  }
  // Update any field-specific flags
  if (fieldConfig) {
    param[compareKey].distance = !!fieldConfig.distance;
    param[compareKey].date = fieldConfig.input === Date;
    param[compareKey].condition = !!fieldConfig.condition;
    param[compareKey].class = fieldConfig.input === String;

    // Set available options for this field
    param[compareKey].fieldOptions = getInputOptionsForField(fieldConfig);

    // Reset value if changing field type
    if (param[compareKey].value) {
      param[compareKey].value = "";
    }
  }
};

const getInputOptionsForField = (fieldConfig) => {
  if (!fieldConfig) return [];

  const input = fieldConfig.input;
  if (typeof input === "object" && Array.isArray(input)) {
    return input;
  } else if (input === Number) {
    return []; // Use number input
  } else if (input === String) {
    return []; // Use text input
  } else if (input === Date) {
    return []; // Use date input
  } else {
    return input || []; // Return any predefined options
  }
};
const validateForm = () => {
  let isValid = true;
  stepErrors.basic.groupName = "";
  stepErrors.basic.name = "";
  stepErrors.sentences.order = "";
  stepErrors.basic.description = "";

  if (!formData.basic.groupName) {
    stepErrors.basic.groupName = "Group name is required";
    isValid = false;
  }

  if (!formData.basic.name) {
    stepErrors.basic.namename = "Rule name is required";
    isValid = false;
  }

  if (formData.basic.order < 0) {
    stepErrors.basic.order = "Order must be a positive number";
    isValid = false;
  }

  if (!formData.basic.description) {
    stepErrors.basic.description = "Description is required";
    isValid = false;
  }

  return isValid;
};
const createRule = async () => {
  console.log("Creating rule:", formData);
  if (!validateForm()) {
    return;
  }
  const enteredGroup = formData.value.basic.groupName;
  const groupExists = filteredGroups.value.some(
    (group) => group.name.toLowerCase() === enteredGroup.toLowerCase()
  );
  if (!groupExists) {
    console.log("Group does not exist:", enteredGroup);
    return;
  }
  const ruleData = {
    ...formData.basic,
    sentences: formData.sentences,
    ruleParameters: formData.parameters,
  };
  try {
    let response;
    if (isEditing.value) {
      // Update existing rule
      response = await API.put(
        "MrCenApiGateway",
        `/comments/${currentRuleId.value}`,
        {
          body: ruleData,
        }
      );

      // Update local state
      const groupIndex = groupedComments.value.findIndex(
        (g) => g.name === ruleData.groupName
      );

      if (groupIndex !== -1) {
        const ruleIndex = groupedComments.value[groupIndex].rules.findIndex(
          (r) => r._id === currentRuleId.value
        );

        if (ruleIndex !== -1) {
          groupedComments.value[groupIndex].rules[ruleIndex] = {
            ...groupedComments.value[groupIndex].rules[ruleIndex],
            ...ruleData,
            _id: currentRuleId.value,
          };
        }
      }

      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Rule updated successfully",
        life: 3000,
      });
    } else {
      response = await API.post("MrCenApiGateway", "/comments", {
        body: ruleData,
      });

      // Update local state for new rule
      const groupIndex = groupedComments.value.findIndex(
        (g) => g.name === ruleData.groupName
      );
      if (groupIndex === -1) {
        groupedComments.value.push({
          name: ruleData.groupName,
          rules: [response],
          id: response._id,
        });
      } else {
        groupedComments.value[groupIndex].rules.push(response);
        groupedComments.value[groupIndex].rules.sort(
          (a, b) => (a.order || 0) - (b.order || 0)
        );
      }

      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Rule created successfully",
        life: 3000,
      });
    }
    resetForm();
    isEditing.value = false;
    currentRuleId.value = null;
    currentStep.value = 0;
  } catch (error) {
    console.error("Error creating rule:", error);
  }
};

const resetForm = () => {
  formData.basic = {
    groupName: "",
    name: "",
    order: 0,
    description: "",
    active: true,
    direction: 1,
  };

  formData.sentences = [
    {
      id: "1",
      sentence: "",
      order: 1,
      usecount: 0,
    },
  ];

  formData.parameters = [
    {
      type: "compare",
      compare1: {
        phase: "dbForm",
        field: "",
      },
      compare2: {
        phase: "staticValue",
        value: "",
      },
      compare3: {
        phase: "dbForm",
        field: "",
      },
      compare4: {
        phase: "dbForm",
        field: "",
      },
      compareFunc: "equalTo",
      compareFunc3: "equalTo",
    },
  ];
};
</script>
<style scoped>
/* Base style for each step item */
.sentences-container {
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #f8fafc;
}

.sentence-item {
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
  transition: all 0.3s ease;
}

.sentence-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.drag-handle {
  padding: 0.5rem;
  color: #94a3b8;
  transition: color 0.2s ease;
}

.drag-handle:hover {
  color: #475569;
}

:deep(.p-inputtext),
:deep(.p-inputnumber-input) {
  padding: 0.5rem 0.75rem;
}

:deep(.p-button.p-button-outlined) {
  padding: 0.5rem;
}

:deep(.p-button.p-button-outlined.p-button-danger) {
  width: 2.5rem;
  height: 2.5rem;
}
.custom-step-item {
  display: inline-block;
  list-style: none;
  margin-right: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
}
.custom-step-item::before {
  display: none !important;
}
/* Styling the link inside the step */
.custom-step-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 2px solid #ccc;
  border-radius: 4px;
  color: #141111;
}

/* Number indicator inside the step */
.custom-step-number {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #eee;
  margin-right: 0.5rem;
  font-weight: bold;
}

/* Title of the step */
.custom-step-title {
  font-size: 1rem;
}

/* Active state for the step */
.custom-step-active .custom-step-link {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #fff;
}

/* Active state for the number indicator */
.custom-step-active .custom-step-number {
  background-color: #005fa3;
  color: #fff;
}
/* Valid step styling (green) */
.custom-step-valid .custom-step-link {
  border-color: green;
  background-color: green;
  color: #fff;
}

/* Invalid step styling (red) */
.custom-step-invalid .custom-step-link {
  border-color: red;
  background-color: red;
  color: #fff;
}

/* Optional styling for the navigation buttons */
.step-navigation {
  margin-top: 1rem;
}
.p-inputtext,
.p-dropdown,
.p-inputnumber,
.p-textarea {
  border: 1px solid #ced4da !important;
  background: #ffffff !important;
  transition: all 0.3s ease;
  width: 100%;
}

.p-inputtext:enabled:hover,
.p-dropdown:hover {
  border-color: #3b82f6 !important;
}

.p-inputtext:enabled:focus {
  border-color: #3b82f6 !important;
  box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.25) !important;
}
.rules-manager-page {
  --rm-primary: #3b82f6;
  --rm-danger: #dc2626;
  --rm-success: #16a34a;
  --rm-gray: #64748b;
  --rm-gray-light: #f8fafc;
  --rm-border: #e2e8f0;
  --rm-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  --rm-transition: all 0.3s ease;

  /* Wizard container styling */
  .rule-wizard {
    max-width: 1200px;
    margin: 0 auto;
    transition: all 0.3s ease;
  }
  :deep(.p-invalid) {
    border-color: #dc2626 !important;
  }

  :deep(.p-invalid:enabled:hover) {
    border-color: #dc2626 !important;
  }

  :deep(.p-invalid:enabled:focus) {
    border-color: #dc2626 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25) !important;
  }

  /* Error message styling */
  .text-red-500 {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
  }

  .rule-wizard.editing-mode {
    margin-top: 0;
  }

  .form-grid {
    display: grid;
    gap: 1rem;
  }

  .parameter-grid {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .v-icon {
    font-size: 1.25rem !important;
    margin-right: 4px;
  }
  .p-button.p-button-text :deep(span) {
    font-size: 1rem !important;
    font-weight: 500;
  }

  /* .rules-manager styles */
  .rules-manager {
    transition: opacity 0.3s ease-in-out;

    .rule-card {
      background: white;
      border-radius: 8px;
      padding: 1rem;
      margin-bottom: 1rem;
      border: 1px solid #e2e8f0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
    }

    .rule-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    .rule-title {
      display: flex;
      align-items: center;
      gap: 1rem;

      h3 {
        margin: 0;
        font-size: 1.25rem;
      }
    }

    .rule-content {
      .rule-info {
        margin-bottom: 1rem;
      }

      .rule-order {
        font-size: 0.875rem;
        color: #64748b;
      }

      .rule-description {
        margin: 0.5rem 0;
        color: #1e293b;
      }
    }

    .rule-sentences,
    .rule-parameters {
      margin-top: 1rem;

      h4 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 0.25rem 0;
          font-size: 0.875rem;
        }
      }
    }

    .rule-direction {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      border-radius: 9999px;
      font-size: 0.75rem;
      font-weight: 500;
      margin-top: 1rem;

      &.direction-positive {
        background-color: #dcfce7;
        color: #16a34a;
      }
      &.direction-negative {
        background-color: #fee2e2;
        color: #dc2626;
      }
      &.direction-neutral {
        background-color: #f1f5f9;
        color: #64748b;
      }
    }

    .no-rules,
    .no-groups {
      text-align: center;
      padding: 2rem;
      color: #64748b;
      background: #f8fafc;
      border-radius: 8px;
    }

    /* PrimeVue Accordion override, namespaced in .rules-manager-page */
    :deep(.p-accordion) {
      .p-accordion-header-link {
        background: #f8fafc;
        border: none;
        border-radius: 8px;
        margin-bottom: 0.5rem;
        font-weight: 600;
        transition: background-color 0.2s;

        &:hover {
          background: #f1f5f9;
        }
      }

      .p-accordion-content {
        border: none;
        background: transparent;
        padding: 1rem 0;
      }
    }
  }

  .highlight-rule {
    border: 2px solid #4f46e5 !important;
    background-color: #eef2ff !important;
    transition: all 0.3s ease;
  }

  .search-container :deep(.p-input-icon-left) {
    width: 100%;
  }
  .search-container :deep(.p-input-icon-left i) {
    color: #64748b;
  }
  .search-container :deep(.p-input-icon-left input) {
    width: 100%;
    padding-left: 2.5rem;
  }

  .review-section-part {
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }

  .parameter-review {
    padding: 1rem;
    margin-bottom: 1rem;
    background: #f8fafc;
    border-radius: 6px;
  }

  .parameter-type {
    font-weight: 600;
    color: #3b82f6;
    margin-bottom: 0.5rem;
  }

  .parameter-logic {
    font-family: monospace;
    background: white;
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }

  .sentence-review {
    display: flex;
    gap: 1rem;
    align-items: baseline;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e2e8f0;
  }

  .sentence-order {
    font-weight: 600;
    color: #64748b;
  }

  .placeholder-help {
    padding: 1rem;
    background: #f1f5f9;
    border-radius: 6px;
    margin-bottom: 1.5rem;
  }

  /* Accordion animation */
  :deep(.p-accordion-content-wrapper) {
    transition: all 0.3s ease-in-out;
  }

  .steps-container {
    width: 60%;
  }
}
</style>
